export const careerSec1 = [
  {
    img: "/assets/images/career_images/LifeatDT(1).svg",
    subTitle: "Life at DT",
  },
  {
    img: "/assets/images/career_images/Benefits(1).svg",
    subTitle: "Perks & Benefits",
  },
  {
    img: "/assets/images/career_images/Recruitment(1).svg",
    subTitle: "Recruitement Process",
  },
  {
    img: "/assets/images/career_images/OpenPositions(1).svg",
    subTitle: "Open Positions",
  },
  {
    img: "/assets/images/career_images/Locations(3).svg",
    subTitle: "Locations",
  },
  {
    img: "/assets/images/career_images/ReachOutToUs(1).svg",
    subTitle: "Reach Out To Us",
  },
];

export const careerSec2 = [
  {
    img: "/assets/images/career_images/Rectangle 290.svg",
    title: "What we look for",
  },
  {
    img: "/assets/images/career_images/Rectangle 290.svg",
    title: "Sustainability",
  },
  {
    img: "/assets/images/career_images/Rectangle 290.svg",
    title: "Working at Data Template",
  },
  {
    img: "/assets/images/career_images/Rectangle 290.svg",
    title: "Privacy Policy",
  },
];

export const expertise = [
  {
    img: "/assets/images/industries/Healthcare.svg",
    title: "Healthcare",
    industryType: "Healthcare",
    color: "#ffffff",
    button_background: "#000000",
    button_color: "#ffffff",
  },
  {
    img: "/assets/images/industries/Finance.svg",
    title: "Finance, Mortgage, Real Estate & Insurance",
    industryType: "Finance, Mortgage & Real Estate",
    color: "#ffffff",
    button_background: "#ffffff",
    button_color: "#000000",
  },
  {
    img: "/assets/images/industries/Satellite.svg",
    title: "Satellite & Telecom",
    industryType: "Satellite & Telecom",
    color: "#ffffff",
    button_background: "#000000",
    button_color: "#ffffff",
  },
  {
    img: "/assets/images/industries/Manufacturing.svg",
    title: "Manufacturing",
    industryType: "Manufacturing",
    color: "#ffffff",
    button_background: "#ffffff",
    button_color: "#000000",
  },
  {
    img: "/assets/images/industries/Government.svg",
    title: "Government",
    industryType: "Healthcare Government",
    color: "#ffffff",
    button_background: "#000000",
    button_color: "#ffffff",
  },
  {
    img: "/assets/images/industries/Education.svg",
    title: "Education",
    industryType: "Education",
    color: "#ffffff",
    button_background: "#ffffff",
    button_color: "#000000",
  },
  {
    img: "/assets/images/industries/Aerospace.svg",
    title: "Aerospace & Defence",
    industryType: "Aerospace & Defense",
    color: "#ffffff",
    button_background: "#000000",
    button_color: "#ffffff",
  },
  {
    img: "/assets/images/industries/Retail.svg",
    title: "Retail",
    industryType: "Retail",
    color: "#ffffff",
    button_background: "#ffffff",
    button_color: "#000000",
  },
  {
    img: "/assets/images/industries/Travel-transportation.svg",
    title: "Travel & Transport Logistics",
    industryType: "Travel & Transport Logistics",
    color: "#ffffff",
    button_background: "#000000",
    button_color: "#ffffff",
  },
  {
    img: "/assets/images/industries/Non-profit.svg",
    title: "Non - Profit Organization",
    industryType: "Healthcare Non- Profit Organization",
    color: "#ffffff",
    button_background: "#ffffff",
    button_color: "#000000",
  },
  {
    img: "/assets/images/industries/Hospitality.svg",
    title: "Hospitality & Leisure",
    industryType: "Hospitality & Leisure",
    color: "#ffffff",
    button_background: "#000000",
    button_color: "#ffffff",
  },
];

export const about = [
  {
    id: "founder",
    img: "/assets/images/aboutus/anil.svg",
    title: "Fundador & CEO",
    subTitle: "Anil Kumar Parakkad",
  },
  {
    id: "finance",
    img: "/assets/images/aboutus/rajesh.svg",
    title: "Co-Fundador & VP Finanzas y Operaciones",
    subTitle: "Rajesh Koyakkeel",
  },

  {
    id: "tech",
    img: "/assets/images/aboutus/sreejesh.svg",
    title: "Co-Fundador & VP Código abierto",
    subTitle: "Sreejesh Kumar",
  },
  {
    id: "techno",
    img: "/assets/images/aboutus/anoop.svg",
    title: "Co-Fundador & VP Tecnologías Microsoft",
    subTitle: "Anoop Wellington",
  },
  {
    id: "business",
    img: "/assets/images/aboutus/dhaneshupdate.svg",
    title: "VP Desarrollo de Negocios & Relaciones con Clientes",
    subTitle: "Dhanesh Menon",
  },
  {
    id: "operations",
    img: "/assets/images/aboutus/sukeshnew.svg",
    title: "VP Operaciones en USA",
    subTitle: "Sukesh Vadake Illam",
  },
];

export const serviceimg = [
  {
    id: "AD",
    serviceType: "Application Development",
    img: "/assets/images/services/applicationdevelopment.svg",
    title: "Desarrollo de Aplicaciones",
    subTitle: "Technology Expertise",
    subTitle2:
      "Angular New, Django, Dot Net, Ext-Js, Go, Java, Lamp, Microsoft SQL- Server, My SQL, Node Js, Oracle Database, PHP, Python, Rails, React",
  },
  {
    id: "BI",
    serviceType: "Business Intelligence & Big Data Analysis",
    img: "/assets/images/services/businessintelligence.svg",
    title: "Inteligencia de Negocios & Análisis Big Data",
    subTitle: "Technology Expertise",
    subTitle2:
      "DB, Grafana, Graphite, Hadoop, Informatica,Julia,Kafka, Kirbana, Power-BI, Spark, Tableau, Yardi",
  },
  {
    id: "CD",
    serviceType: "Cloud & DevOps",
    img: "/assets/images/services/devops.svg",
    title: "Cloud & DevOps",
    subTitle: "Technology Expertise",
    subTitle2:
      "Amazon Web Services, Cloudify, Firebase, Google-Cloud, Microsoft Azure, Microsoft System Center, Oen Stack-Cloud Software, Vmware",
  },
  {
    id: "Mobility",
    serviceType: "Mobility",
    img: "/assets/images/services/mobility.svg",
    title: "Aplicaciones móviles",
    subTitle: "Technology Expertise",
    subTitle2:
      "Android, Apple, Flutter, Kotlin, Phone Gap,React-Native, Titanium, USSD",
  },
  {
    id: "SI",
    serviceType: "Service Intergration",
    img: "/assets/images/services/serviceintegration.svg",
    title: "Integración de servicios",
    subTitle: "Technology Expertise",
    subTitle2:
      "Apache- Airflow, Carz-insp, Drools, Equifax, FedEx, FlexRule, Hive, Mulesoft, Skye- Closings, Twilio",
  },
  {
    id: "ST",
    serviceType: "Software Testing",
    img: "/assets/images/services/softwaretesting.svg",
    title: "Testing de Software",
    subTitle: "Technology Expertise",
    subTitle2:
      "Asana, Jira Software, Jmeter, Katalon, Lamada Test, Mantis Bug Tracker, SE, SoapUI Pro, Testing, Test Link, Test Lodge",
  },
  {
    id: "IOT",
    serviceType: "Embedded & IoT",
    img: "/assets/images/services/embedded.svg",
    title: "Aplicaciones embebidas & IoT",
    subTitle: "Technology Expertise",
    subTitle2:
      "3G, C++, Cisco, Embedded-c, Java, Kaa, LTE, Nokia, Python, Tellabs, Wimax",
  },
  {
    id: "ML",
    serviceType: "Machine Learning & Artificial Intelligence",
    img: "/assets/images/services/machinelearning.svg",
    title: "Machine Learning & Inteligencia Artificial",
    subTitle: "Technology Expertise",
    subTitle2: "Jupyter, Pandas, Plotiy, R, Timeseries, Web Scrapping",
  },
  {
    id: "nft",
    serviceType: "NFT & Blockchain",
    img: "/assets/images/services/nft.svg",
    title: "NFT & Blockchain",
    subTitle: "Technology Expertise",
    subTitle2: "C++, Kaa, Python, Unity",
  },
  {
    id: "AR",
    serviceType: "Augmented Reality & Virtual Reality",
    img: "/assets/images/services/reality.svg",
    title: "Realidad Aumentada & Realidad Virtual",
    subTitle: "Technology Expertise",
    subTitle2: "C++, Kaa, Python, Unity",
  },
  // {
  //   id: "AR",
  //   serviceType:'Cyber Security',
  //   img:"/assets/images/services/cybersecurity.svg",
  //   title: "Cyber Security",
  //   subTitle:"Technology Expertise",
  //   subTitle2: "C++, Kaa, Python, Unity"
  // }
];
export const serviceFooterimg = [
  {
    id: "AD",
    title: "Application Development",
  },
  {
    id: "BI",
    title: "BI & Big Data Analytics",
  },
  {
    id: "CD",
    title: "Cloud & DevOps",
  },
  {
    id: "Mobility",
    title: "Mobility",
  },
  {
    id: "SI",
    title: "Service Integration",
  },
  {
    id: "ST",
    title: "Software Testing",
  },
  {
    id: "IOT",
    title: "Embedded & IoT",
  },
  {
    id: "ML",
    title: "ML & AI",
  },
  {
    id: "nft",
    title: "NFT & Blockchain",
  },
  {
    id: "AR",
    title: "AR & VR",
  },
];

export const clientimgs = [
  {
    img: "assets/images/clients/Viasat.svg",
    title: "Viasat",
    subTitle: "Network Speed Test Tool",
  },
  {
    img: "assets/images/clients/Ibslogo.svg",
    title: "IBS Software",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Corelogic.svg",
    title: "Core Logic",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Bank.svg",
    title: "Sunflower Bank",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Techouse(1).svg",
    title: "BK Techouse",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Gorillagames.svg",
    title: "Gorilla Games",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Technopro.svg",
    title: "Technopro",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/ADA.svg",
    title: "Aeronautical Development Agency",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Pantrycook.svg",
    title: "Pantry Cook",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Factoreal.svg",
    title: "Factoreal",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Vertaag.svg",
    title: "DLED",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/AttomSolutions.svg",
    title: "ATTOM Data Solutions",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Phfi.svg",
    title: "Public Health Foundation of India",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Stronghome.svg",
    title: "Strong Home Mortgage",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Cbzhold.svg",
    title: "CBZ Holdings",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Securiti.svg",
    title: "Securiti",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Charllotte.svg",
    title: "Charlotte Russe",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Nwu.svg",
    title: "Northwestern University",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Defrnd.svg",
    title: "Defence Research & Development Organisation",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Netset.svg",
    title: "NetSet Communications",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Ucl.svg",
    title: "University College London",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/updintertrust.svg",
    title: "Intertrust",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Intersystems.svg",
    title: "InterSystems",
    subTitle: "Lorem Ipsum",
  },
];

export const caseimgs2 = [
  {
    img: "/assets/images/casestudies/applicationbuild.svg",
    date: "Octubre 2020",
    id:1,
    title: "Creación e implementación de aplicaciones en AWS",
    service: ["Application Development"],
    industry: ["Finance"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/aws1.svg",
        desc: "Uno de los puntos de preocupación de nuestro cliente era que, al principio, el equipo de desarrollo solía lanzar la compilación a QA manualmente, por lo que se dedicó mucho tiempo al proceso de lanzamiento de la compilación. Además, el equipo solía almacenar las credenciales relacionadas con la base de datos en GIT, para que aquellos que tenían acceso al entorno pudieran acceder fácilmente a toda la información que pudiera conducir a problemas de seguridad en el proyecto. Debido a todo esto, los clientes querían migrar su infraestructura a AWS, ya que estaba alojada en un entorno que no era de la nube.",
        subTitle1: "Desafío",
        subtitle2: "¿Cómo lo superamos?",
        img2: "/assets/images/casestudyinfo/aws2.svg",
        subtitle3: "Beneficios",
        point: {
          desc1: [
            "El equipo solía almacenar todas las credenciales relacionadas con la base de datos en GIT. Luego investigamos de cerca algunas herramientas de DevOps para encontrar la mejor solución posible y llegamos a la conclusión de que almacenar todas las credenciales en la bóveda podría ser la mejor opción. Por lo tanto, el equipo de DevOps ahora tenía un desafío en cuanto a cómo podríamos crear carpetas secretas para el cliente y obtener la bóveda y sobrescribirlas en archivos específicos.",
          ],
          desc2: [
            "Hemos añadido un nuevo archivo .txt de la versión junto con el código fuente, y con el lanzamiento de un nuevo código fuente, el equipo de desarrollo actualizará el archivo release.txt. Jenkins sondeará la rama maestra o cualquier nueva confirmación ocurrirá con la rama requerida, el código se revisará en el nodo de jenkins donde se implementa la aplicación. Con la opción de compilación parametrizada en jenkins, definimos la variable de entorno en la compilación principal y la pasamos como un parámetro de cadena a las compilaciones posteriores mientras enviamos el informe de compilación consolidado. Hemos configurado todas las credenciales relacionadas con la infraestructura y la base de datos del cliente en la bóveda y, mientras se crean trabajos de Jenkins, las credenciales se están obteniendo de la bóveda y sobrescribiendo a archivos específicos en el momento del inicio de la aplicación. Además, el acceso a la interfaz de usuario de la bóveda estará restringido al equipo de DevOps. Usamos el script Terraform para manejar la configuración de AWS Infra. Luego, en el disparador de Jenkins, estamos construyendo la imagen de docker que más tarde se envía a ECR y en el momento de la implementación estamos obteniendo imágenes del ECR e implementando la aplicación en función de los parámetros que se pasan de la compilación principal.",
          ],
          desc3: [
            "Los beneficios de introducir este proceso fueron la implementación de la integración continua, los desarrolladores pueden hacer commits de código frecuentes que ayudan a identificar errores y problemas relacionados con la calidad de manera más rápida y temprana, implementaciones seguras y una disminución del tiempo completo de compilación. No se necesita intervención manual después de que el código se haya enviado a un repositorio. Después de completar con éxito el proceso de implementación, el correo se activará automáticamente tanto en el caso de fracaso como en el de éxito. ECR, que proporciona la función de escaneo de imágenes, ayuda a identificar las vulnerabilidades del software en las imágenes de los contenedores. Uno de los otros beneficios es que, dado que hemos introducido una bóveda para almacenar todas credenciales relacionadas relacionadas con la infraestructura, del cliente, es más seguro que nunca. El equipo de desarrollo ha estado satisfecho con el proceso de construcción automatizado y también ha sido fácil de manejar las implementaciones de aplicaciones sin realizar mucho esfuerzo.",
          ],
        },
      },
    ],
  },

  {
    img: "/assets/images/casestudies/machinelearning.svg",
    date: "Septiembre 2020",
    id: 2,
    title: "Modelo ML de recomendación de recetas (Machine Learning)",
    service: [
      "Application Development",
      "Machine Learning & Artificial Intelligence",
    ],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/machinelearning1.svg",
        desc: "La versión existente de nuestra aplicación solía recomendar recetas aceptando ingredientes del usuario e iniciando una llamada a la API externa que dio lugar a recetas con ingredientes preferidos por el usuario y adicionales. En este caso, los resultados fueron inexactos porque el número de ingredientes coincidía mediante una búsqueda dinámica. Para superar este inconveniente, habilitamos el modelo de aprendizaje automático para la recomendación de recetas. ",
        subTitle1: "Desafío ",
        subtitle2: "¿Cómo lo superamos/locamos?",
        img2: "/assets/images/casestudyinfo/machinelearning2.svg",
        subtitle3: "Beneficios",
        point: {
          desc1: [
            "Los datos originales disponibles en la aplicación tenían menos registros y no había muchas fuentes de recetas disponibles en Internet. Buscamos una fuente de datos estática para empezar a evaluar los modelos. Comenzamos a recopilar datos de varios sitios web de recetas que eran áridos y desafiantes, por ejemplo, los robots de algunos sitios web bloquearon nuestro agente de web scrapping. Realizamos la limpieza de datos varias veces para los stakeholders la revisaren y dieran su aprobación. Los datos recopilados de algunas fuentes de recetas fueron mas consideradas que las del resto de fuentes. Encontrar el mejor algoritmo para los datos (fue) una de las tareas más importantes.",
          ],
          desc3: [
            "La recomendación de recetas basada en la IA es precisa, genera tendencia y le gusta a los usuarios. Los ingredientes de las recetas resultantes coinciden muy bien con los ingredientes del usuario. El usuario podría elegir las mejores recetas calificadas y mejor de los resultados. Mediante el uso de un modelo preentrenado, los resultados son mucho más rápidos que la búsqueda dinámica.",
          ],
          desc2: [
            "Comenzamos recopilando datos de recetas e ingredientes asociados. Hicimos web scrapping en algunos sitios web de recetas y también utilizamos API compatibles para obtener más datos de recetas. Limpiamos y procesamos los datos para llegar a una lista única de ingredientes y recetas. Luego comenzamos a evaluar varios modelos de aprendizaje automático y analizamos la precisión de los resultados que mejor se ajustaban a las necesidades y el uso del cliente, Luego entrenamos modelos y probamos las predicciones de recetas para un conjunto de ingredientes. Una vez que se completaron la capacitación y las pruebas, implementamos el modelo de aprendizaje automático como un servicio REST, al que la aplicación móvil podía llamar gratis tantas veces.",
          ],
        },
      },
    ],
  },
  {
    id: 3,
    img: "/assets/images/casestudies/stockpile.svg",
    date: "Octubre 2020",
    title: "Medición de reservas",
    service: ["Embedded & IoT", "Application Development"],
    industry: ["Aerospace & Defence"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/stockpile1.svg",
        desc: "Si bien los topógrafos y administradores de sitios reconocen el valor de los inventarios físicos in situ de volúmenes de existencias con fines contables, la mayoría de ellos admitirían que sigue siendo un proceso que consume mucho tiempo y es costoso, que a menudo requiere que los topógrafos externos realicen el análisis. Además, hay riesgos de seguridad inherentes con las reservas de escalada.",
        subTitle1: "¿Cómo lo superamos?",
        img2: "/assets/images/casestudyinfo/stockpile2.svg",
        subtitle3: "Beneficios",
        point: {
          desc1: [
            "El mapeo de drones es cuando especificas el área que te gusta mapear y el dron vuela alrededor de esa área y hace un mapa 3D de ella, y esta técnica se llama fotogrametría. La topografía aérea con drones permite a los topógrafos medir la reserva con una nube de puntos densa utilizando procesos de fotogrametría. La configuración inicial de los puntos de control en tierra (o GCP) es un paso crucial para garantizar la precisión y la calidad de los datos adquiridos por drones. Los puntos de control de tierra son puntos de coordenadas geoespaciales conocidas dentro de un área de interés.",
            "Una vez que los GCP se colocan y se inspeccionan, el operador vuela el dron y mapea todo el sitio automáticamente tomando varias fotos. Los datos de los drones se cargan en el software WEBODM para su procesamiento automático, para generar modelos 2D y 3D. Los datos procesados consisten en millones de puntos que permiten la reconstrucción 3D del sitio y permiten el cálculo del volumen. El proceso de fotogrametría coincide con los puntos de referencia geográfica que se encuestaron con los GCP con las imágenes superpuestas Tecnologías utilizadas: Python, Shell Scripting, JavaScript y Go.",
          ],
          desc3: [
            "Las mediciones ahora se pueden realizar con drones; la recopilación frecuente de datos está demostrando ser rentable. Permite una mejor previsión de las existencias de minerales disponibles para la venta. Eliminar los riesgos a los que se enfrentan los topógrafos que trabajan físicamente en el sitio. Un método rápido y económico de medición del volumen es particularmente útil para calcular las existencias en minas y canteras con fines de inventario o monitoreo. Con un dron, los topógrafos pueden capturar muchos más puntos de datos topográficos, por lo que las mediciones de volumen son más precisas. Dado que los drones están capturando los datos desde el aire, las operaciones en el sitio no se interrumpirán.",
          ],
        },
      },
    ],
  },
  {
    id: 4,
    img: "/assets/images/casestudies/detection.svg",
    date: "Agosto 2020",
    title:
      "Detección y segregación de señales óptimas de manos para visualización",
    service: ["Application Development"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/detection1.svg",
        desc: "El cliente es una organización de aviación que está utilizando una simulación basada en juegos para preparar y capacitar a su personal para pilotar un avión. La organización del cliente está utilizando el entrenamiento de simulación sobre los métodos típicos convencionales de entrenamiento para aumentar la eficacia del entrenamiento y lo han logrado mediante la integración de la realidad virtual con un dispositivo de movimiento de salto. Sin embargo, solo se empleó un dispositivo de movimiento de salto en su entrenamiento y, dado que el rango de dispositivos de movimiento de salto es menor, el campo de visión en la Realidad Virtual se circunscribió y los movimientos de las manos del aprendiz se restringieron a una distancia particular, lo que resultó en una gran incertidumbre de replicar la experiencia simulada en el mundo real. Por lo tanto, para superar esta restricción, se nos otorgó la tarea de integrar dispositivos de movimiento de salto múltiple para aumentar la precisión y el campo de visión en la simulación.",
        subTitle1: "Tarea y solución presentadas",
        img2: "/assets/images/casestudyinfo/detection2.svg",
        subtitle3: "Beneficios y resultados",
        point: {
          desc1: [
            "Expandir el área de detección de manos empleando múltiples dispositivos Leap Motion. Calibrar manualmente las manos capturadas obtenidas por múltiples dispositivos Leap Motion a un dispositivo maestro. Para superar la limitación actual en la simulación del juego del cliente, implementamos una función que determina cuántas manos están dentro del rango eficaz de los dispositivos Leap-Motion y captura esos datos de la mano. También implementamos una lógica que determina los valores de confianza de cada mano capturada basada en la desviación de cada dedo. Desarrollamos adicionalmente una función que elimina los datos de la mano con valores de confianza de la mano menores. Proporcionando así mejores salidas manuales. Además, hemos empleado un programa que agarra los puntos de la mano de todos los dispositivos y los reubica relativamente con el dispositivo maestro, dependiendo de la distancia y el ángulo entre esos dispositivos. Lenguaje de scripting utilizado: Herramientas/softwares C-sharp utilizados: Visual Studio, Unity 3D, Leap Motion Controller",
          ],
          desc3: [
            "Área de detección de manos: Mediante el uso de múltiples dispositivos de movimiento de salto, estamos aumentando el área de la región de seguimiento de manos para una mejor experiencia de realidad aumentada/resa realidad virtual.",
            "Sincronización de dispositivos: Minimización de los desplazamientos relativos entre las manos para realizar transiciones más suaves y mejores entre dispositivos.",
            "Precisión: Con la implementación de la función de confianza, solo se envía la mejor mano a la simulación entre todas las manos capturadas, lo que proporciona una mayor precisión.",
            "Nuestra implementación proporciona una experiencia más inmersiva para los aprendices con poco o ningún riesgo de incertidumbre y desorientación en la transferencia de su experiencia simulada al mundo real. Dado que las funciones se escriben dinámicamente, se puede conectar cualquier número de dispositivos de movimiento de salto sin ningún cambio en el programa por parte de los propios clientes. Se probaron cuatro movimientos de salto con nuestras funciones que cubrían todo el alcance de un usuario sentado.",
          ],
        },
      },
    ],
  },

  {
    id: 5,
    img: "/assets/images/casestudies/customer.svg",
    date: "Noviembre 2020",
    title: "Mejorar la experiencia del cliente",
    service: ["Application Development"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/customer.svg",
        desc: "Analizar nuevos métodos para capturar o recibir comentarios honestos de los clientes. Para establecer un proceso para medir la salud de los comentarios de satisfacción del cliente. Para tener un proceso sólido y hacer crecer el negocio de nuestros clientes existentes mejorando el proceso de retroalimentación de la satisfacción del cliente.",
        subTitle1: "Descripción General",
        subtitle2: "Problemas enfrentados con el proceso existente",
        img2: "/assets/images/casestudyinfo/customer2.svg",
        subtitle3_half: "Soluciones y beneficios",

        subtitle3: "Conclusión",
        point: {
          desc1: [
            "La satisfacción del cliente siempre sigue siendo el factor clave en el crecimiento del negocio y lo seguirá siendo en el futuro. Con el fin de mantener y obtener negocios continuos de nuestros clientes existentes o nuevos, es muy importante que la organización construya una relación sólida y a largo plazo a través del cumplimiento del cliente. Además, para encontrar la brecha y resolver los problemas por parte del cliente, nuestra organización quería mejorar el proceso de recopilación y análisis de la satisfacción del cliente a través de comentarios genuinos.",
          ],
          desc2: [
            "No se implementaron ni introdujeron métodos en el sistema existente para analizar la desviación o el incumplimiento. Las organizaciones necesitan medir el incremento y la disminución en el crecimiento de la satisfacción del cliente. Conflicto con la apertura en los comentarios de satisfacción.",
          ],
          desc3_half: [
            "El equipo de control de calidad volvió a analizar el sistema existente e identificó las lagunas a las que se enfrentaba la organización. Se nos ocurrió un nuevo proceso para recopilar comentarios sobre la satisfacción del cliente y medir los datos de satisfacción. Actualizamos el patrón de preguntas para ser calificadas o respondidas por los clientes. El nuevo formulario de comentarios de satisfacción del cliente presenta una sección de preguntas colectivas basadas en tres factores a calificar, de altamente insatisfecho a muy satisfecho, junto con algunas preguntas abiertas que le dan al cliente la libertad de expresar sus opiniones sobre el producto o servicios entregados por Data Template. Hemos cambiado a los propietarios de los procesos del equipo del proyecto a un equipo de calidad, ya que somos los que gestionamos y recopilando la satisfacción del cliente ahora. Se preparará un índice trimestral y anual para utilizar gráficos de barras para representar el crecimiento de la satisfacción del cliente.",
            "Fácil acceso para que los clientes se pongan en contacto con el equipo de calidad de Data Template y planteen los problemas/conflictos. Y nos proporciona una forma más clara de obtener comentarios claros de los clientes a través de la comunicación directa. Proceso mejorado para examinar el cumplimiento del cliente."
          ],
          desc3: [
            "Una vez que este nuevo proceso esté en marcha y madure, será fácil para nuestra organización resolver los problemas desde la terminal de nuestro cliente y mantener una mejora continua. Esto ayuda a lograr los objetivos comerciales de nuestros clientes y les proporciona una experiencia de alta calidad mientras hacen negocios con nosotros.",
          ],
        },
      },
    ],
  },
  {
    id: 6,
    img: "/assets/images/casestudies/angular.svg",
    date: "Noviembre 2020",
    title: "Carga diferida en Angular (Lazy loading)",
    service: ["Application Development"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/angular1.svg",
        desc: "La parte más importante del desarrollo es el rendimiento de la aplicación. Generalmente, cuando desarrollamos aplicaciones web simples y más pequeñas utilizando el Framework Angular, ayudando a satisfacer los requerimientos del cliente. Cuando desarrollamos una aplicación más amplia, debemos seguir algunas estructuras y estándares. Puede depender del tipo y volumen de la aplicación. Sin embargo, cuando desarrollamos aplicaciones más grandes, los mismos estándares o estructuras afectarán el rendimiento de la aplicación. En este caso creamos muchas funciones y páginas nuevas para la aplicación existente que nos proporcionaron nuestros clientes y la aplicación se volvió enorme, lo que comenzó a afectar el rendimiento de la aplicación. La aplicación tardaba mucho en cargarse por primera vez. Consumía mucho tiempo y también empezó a afectar el rendimiento de la aplicación. Tuvimos que encontrar una forma eficiente de hacer que esto funcionara y al mismo tiempo centrarnos en mejorar el rendimiento, lo cual era de suma importancia para nosotros.",
        subTitle1: "Solución",
        subtitle3_half: "Infraestructura y tecnologías utilizadas",
        img2: "/assets/images/casestudyinfo/angular2.svg",
        subtitle3: "Beneficios y resultados",
        point: {
          desc1: [
            "Cada problema tiene una solución, y nuestro equipo la encontró. Si queremos proporcionar una solución, necesitamos encontrar la causa raíz del problema principalmente. Después de un análisis exhaustivo, nuestro equipo concluyó que la causa raíz del bajo rendimiento de la aplicación se debió a una forma inadecuada de implementación de enrutamiento.",
            "Luego nos dimos cuenta de que el concepto de carga diferida de Angular no se había implementado y decidimos incorporarlo al proyecto. Eventualmente, implementamos y tuvimos éxito. Ahora la aplicación es fluida y rápida y nuestros clientes están contentos.",
          ],
          desc3_half: [
            "Angular con el concepto de carga deferida",
            "TypeScript",
            "Karma y Jasmin (prueba unitaria)",
          ],
          desc3: [
            "Por lo general, en el desarrollo, una gran aplicación se dividirá en varios módulos. Así que aquí la carga diferida en angular ayuda a cargar los módulos siempre que sea necesario. Esto significa que, si nos dirigimos a la página/componente específico, tendremos que cargar las dependencias. Esto significa que no hay necesidad de cargar todas las dependencias al principio. Podemos cargar las dependencias cuando las necesitemos. Además, el rendimiento no se verá afectado, ya que no cargamos las dependencias de una sola vez.",
            "La función de carga lenta también hace lo mismo. Esto significa que ayuda a cargar las dependencias requeridas cuando navegamos a la página específica. Por lo tanto, se carga más rápido.",
          ],
        },
      },
    ],
  },
  {
    id: 7,
    img: "/assets/images/casestudies/regression.svg",
    date: "Agosto 2020",
    title: "Automatización en pruebas de regresión e implementación de QA en el Data warehouse",
    service: ["Application Development"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/regression1.svg",
        desc: "El término \"ETL\" significa Extraer, Transformar y Cargar (Load). La función principal de una herramienta ETL es extraer los datos de diferentes fuentes, transformarlos y cargarlos en un almacén de datos. En las pruebas convencionales de calidad de datos, para probar múltiples sistemas de origen contra un solo sistema de destino, necesitamos extraer los datos de múltiples sistemas de origen y guardarlos en una hoja de cálculo. Entonces tenemos que comparar estos datos con los datos del sistema de destino.",
        subTitle1: "Desafíos",
        subtitle2: "Nuestras soluciones",
        img2: "/assets/images/casestudyinfo/regression2.svg",
        subtitle3: "Conclusión",
        point: {
          desc1: [
            "Cualquier proyecto de software tendrá múltiples versiones, y esto requiere múltiples pruebas de regresión,  cada release requería mas tiempo del estimado para la ejecución de casos de prueba. Esto requería escribir queries SQL y ejecutarlos manualmente usando editores de SQL, copiar los resultados manualmente a Excel y comparar la data de diferentes fuentes y data warehouses de destino, haciendo comparaciones manuales (método conocido como 'muestreo'). Este proceso consumía demasiado tiempo, recursos intesiva e ineficientemente. Compar la data desde sus fuentes con el data warehouse requirió mas tiempo y esfuerzo.",
          ],
          desc2: [
            "Usando la QuerySurge (herramienta ETL), podemos automatizar el proceso manual de preparación del SQL, ejecución y comparación de los resultados. QuerySurge es la solución inteligente de pruebas de datos para automatizar la validación y las pruebas de los data warehouse y el proceso de pruebas ETL. Crea consultas de prueba de forma rápida y sencilla sin escribir ningún SQL con los asistentes de consulta. Compara los datos de los archivos de origen y los almacenes de datos con el data warehouse de destino o el mas grande. Compara millones de filas y columnas de datos en minutos.",
            "Se programaron pruebas para que se ejecuten (1) inmediatamente, (2) cualquier fecha/hora, o (3) automáticamente después de que terminen los eventos. Se validaron hasta el 100 % de todos los datos hasta 1000 veces más rápido que las pruebas tradicionales. Se aumentó la cobertura de validación de datos. Se automatizaron las pruebas de datos desde el inicio de las pruebas hasta completar la validación, el envío automatizado de los resultados por correo electrónico y la actualización de su sistema de gestión de pruebas. La tecnología utilizada para esto fue QuerySurge (herramienta ETL) que utiliza QueryWizards",
          ],
          desc3: [
            "Los requisitos comerciales seguirán cambiando debido a las demandas comerciales, habrá una necesidad de volver a probar los cambios de datos solicitados por los clientes, por lo que se necesita la automatización de las pruebas de regresión para probar el data warehouse involucrado dentro de la herramienta ETL.",
          ],
        },
      },
    ],
  },
  {
    id: 8,
    img: "/assets/images/casestudies/networkspeed.svg",
    date: "Septiembre 2020",
    title: "Herramienta de Medición de Velocidad para Redes",
    service: ["Application Development"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/networkspeed1.svg",
        desc: "Un estudio de caso sobre la herramienta de prueba de velocidad de red que utiliza selenium python para recuperar varios parámetros de velocidad de la red mientras se transmiten videos y se cargan sitios web para escritorio y móviles para una empresa líder de telecomunicaciones con sede en EE. UU.",
        subTitle1: "Situación del cliente",
        subtitle2: "Nuestra solución",
        img2: "/assets/images/casestudyinfo/networkspeed2.svg",
        subtitle3_half: "Cómo Data Template aportó valor al Negocio",
        subtitle3: "Varias tecnologías y herramientas utilizadas para la automatización Lenguajes",
        point: {
          desc1: [
            "Nuestros clientes, siendo los mejores en lo que hacen, deciden tomar la iniciativa de mejorar la eficiencia del servicio de Internet para sus clientes para los que se hicieron múltiples mejoras en la aplicación. ¿Qué sigue? Estas mejoras necesitaban ser verificadas. Ahí fue cuando se volvieron hacia nosotros. Desarrollar una herramienta que pueda capturar varios parámetros de red mientras se transmite un vídeo o un sitio web a través de Internet. Se esperaba que el kit de herramientas se introdujera con la URL y los parámetros de la red para ser capturados y se esperaba que simulara esto ya sea en una computadora portátil o móvil en diferentes plataformas como windows, linux, mac, android e IOS. Sobre la base de los puntos de referencia establecidos, se suponía que el kit de herramientas debía indicar si se logran los resultados esperados.",
          ],
          desc2: [
            "Definimos funciones que podrían devolver varios parámetros de red utilizando el framework de selenium con python como lenguaje script. También creamos e incluimos varias bibliotecas para cargar sitios web, transmitir vídeos, para diferentes sitios web y obtener detalles sobre varios parámetros relacionados con la velocidad para los entornos de escritorio como instagram, netflix, youtube y twitch.tv.",
            "También creamos e incluimos bibliotecas similares para probar la velocidad de la red móvil utilizando una herramienta llamada \"Appium\" que es similar a Selenium y podría permitirnos simular un dispositivo móvil y ejecutar pruebas en ellos. Se incluyeron algunas otras bibliotecas para iniciar llamadas de Skype y comprobar la estabilidad de la red. La principal ventaja de estas herramientas es que no solo da velocidades de carga y descarga como muchas otras herramientas de prueba de velocidad, sino que también le da otros \"parámetros de red\", como la salud del búfer de videos de transmisión, el recuento de caídas de fotogramas, el tiempo de resolución de DNS, la conexión del sitio web y mucho más.",
          ],
          desc3_half: [
            "Obtuvimos la mejor relación calidad-precio al utilizar tecnología de código abierto para desarrollar la herramienta. Logramos poner bajo la lupa problemas con la red y la estabilidad de la misma. La precisión de la herramienta era muy exacta, lo que de hecho ayudó a nuestro cliente a modificar y configurar su red según los resultados precisos del parámetro de velocidad de la red con el fin de proporcionar una mejor experiencia de Internet a los clientes.",
          ],
          desc3: [
            "Lenguajes de scripting: Python selenium, Ansible",
            "Herramientas: Selenium webdriver, Appium, Android SDK, Xcode",
          ],
        },
      },
    ],
  },
  {
    id: 9,
    img: "/assets/images/casestudies/mpen.svg",
    date: "Marzo 2022",
    title: "mPen",
    service: ["Application Development"],
    industry: ["Healthcare", "Non - Profit Organization"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/mpen1.svg",
        desc: "El mPen es un software electrónico de apoyo a la decisión clínica (DSS), para el uso de los proveedores de atención médica, que facilita el manejo basado en la evidencia de la hipertensión y la diabetes y estandariza la prestación de atención en todos los centros de salud.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle3: "Infraestructura y tecnología utilizadas",
        subtitle2: "Planteamiento del Problema y la Solución",
        point: {
          desc1: [
            "Arquitectura, diseño, desarrollo e implementación de aplicaciones",
            "Integración de algoritmos para el sistema de decisión",
            "Sincronizar los datos de la aplicación con el servidor centralizado",
          ],
          desc3: [
            "Base de datos: MySQL",
            "Servicios web: Descanso",
            "Interfaz de usuario: Microsoft WPF",
            "Dot Net y My SQL",
          ],
          desc2: [
            "Desarrollo de la interfaz de usuario para la recopilación de datos",
            "Integración de algoritmos para la toma de decisiones",
            "Sincronizar datos con el servidor centralizado",
            "Despliegue en máquinas de usuario",
            "Arquitecto, diseño, desarrollo e implementación de aplicaciones",
            "Integración de algoritmos para el sistema de decisión",
            "Sincronizar los datos de la aplicación con el servidor centralizado",
          ],
        },

        img2: "/assets/images/casestudyinfo/mpen2.svg",
      },
    ],
  },
  {
    id: 10,
    img: "/assets/images/casestudies/mpower.svg",
    date: "Marzo 2022",
    title: "mPower",
    service: ["Application Development"],
    industry: ["Healthcare", "Government"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/mpower1.svg",
        img2: "/assets/images/casestudyinfo/mpower2.svg",
        desc: "El mPower Heart es un software electrónico de apoyo a la decisión clínica (CDSS), para el uso de proveedores de atención médica, que facilita el manejo basado en la evidencia de la hipertensión y la diabetes y estandariza la prestación de atención en todos los centros de salud.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Planteamiento del Problema y la Solución",
        subtitle3: "Infraestructura y tecnología utilizadas",
        point: {
          desc1: [
            "Arquitecto, Diseño de aplicaciones",
            "Desarrollo y Soporte de Hosting y mantenimiento del ambiente de producción",
            "Ajuste del rendimiento",
          ],
          desc2: [
            "La aplicación para Android se ejecuta en Tablets",
            "Soporte de decisiones para la detección y administración computando mas de 1200 escenarios",
            "Almacena los datos de los pacientes en formato electrónico. Ayuda para asesorar a los pacientes",
            "Arquitectura, Diseño de Aplicaciones, Desarrollo y Alojamiento",
            "Soporte y mantenimiento del entorno de producción",
            "Ajuste del rendimiento",
          ],
          desc3: [
            "Base de datos MSSQL My SQL, Web Services Azure, VSTS, Red Hat Gestión de API a escala 3",
            "MySQL, Dot net core, Angular, Node JS, PHP, React Native, servidor Microsoft SQL",
            "Aplicación de servidor: Java, my SQL DB, Spring, Hibernate",
            "Analytics: Apache spark, Mongo DB, R Analytics.",
            "Entorno de implementación: Microsoft Azure, Apache Tomcat Server",
            "Plataforma/dispositivos compatibles: escritorio de Windows, tabletas Andriod.",
            "Aplicación para Android: base de datos SQL lite, nivel de API 22, Material Design.",
            "Aplicaciones de Dotnet: visual studio 2015, Windows presentation foundation, ADO.net Java, Android, SQL lite, Retrofit.",
          ],
        },
      },
    ],
  },
  {
    id: 11,
    img: "/assets/images/casestudies/signable.svg",
    date: "Abril 2022",
    title: "Aplicación para Gesticuladores de señas",
    service: ["Mobility"],
    industry: ["Healthcare"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/signable1.svg",
        desc: "Una solución de comunicación para el servicio de retransmisión de vídeo (VRS) para sordos. Sign Able es una aplicación móvil de vídeo + voz que ayuda a los usuarios sordos/con problemas de audición a comunicarse con cualquier persona, en cualquier momento y en cualquier lugar con la ayuda de intérpretes de lenguaje de señas, comúnmente conocido como Video Relay Service (VRS).",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle3: "Infraestructura y Tecnologías utilizadas",
        subtitle2: "Planteamiento del Problema y la Solución",
        point: {
          desc1: [
            "Arquitectura, diseño, desarrollo e implementación de aplicaciones",
            "Sincronizar los datos de la aplicación con el servidor centralizado",
          ],
          desc3: [
            "Aplicación móvil: Android",
            "Backend: Python, Django y Django REST, servidor EC2 (AWS)",
            "Comunicación por vídeo: web rtc y quickblox Java, Android, SQL lite, Django.",
          ],
          desc2: [
            "Las personas sordas se enfrentan al aislamiento social, la mayoría son analfabetas y tienen poco acceso a la educación y el empleo. Para facilitar la comunicación de la comunidad sorda, la aplicación de Signaler ofrece a las personas sordas un servicio de interpretación móvil en lenguaje de señas indio, que les permite comunicarse con cualquier persona, en cualquier lugar y en cualquier momento. Signable es una aplicación móvil de vídeo y voz que ayuda a los usuarios sordos/con problemas de audición a comunicarse con cualquier persona, en cualquier momento y en cualquier lugar con la ayuda de intérpretes de lenguaje de señas, comúnmente conocido como Video Relay Service (VRS). Aplicación de llamadas bidireccionales para que la familia, los amigos y otros proveedores de servicios esenciales puedan llamar a personas sordas; Optimiza la interfaz de usuario de la aplicación para mejorar la experiencia del usuario y la optimización de la transmisión de vídeo para configuraciones de bajo ancho de banda.",
          ],
        },
        img2: "/assets/images/casestudyinfo/signable2.svg",
      },
    ],
  },
  {
    id: 12,
    img: "/assets/images/casestudies/stemi.svg",
    date: "Febrero 2022",
    title: "Aplicación web para el tratamiento de ataques al corazón",
    service: [],
    industry: ["Healthcare", "Non - Profit Organization"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/stemi1.svg",
        desc: "STEMI Charitable Trust es una organización sin fines de lucro dedicada a proporcionar diagnóstico y tratamiento indígena y asequible de los ataques cardíacos STEMI. Fue desarrollado en colaboración con investigadores nacionales e internacionales en medicina interna, medicina de emergencia y cardiología intervencionista.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        subtitle2: "Planteamiento del Problema y la Solución",
        point: {
          desc1: [
            "Rendimiento y validación de datos.",
            "Visualización de datos y supervisión de aplicaciones de datos.",
            "Desarrollo de API REST.",
          ],
          desc3: [
            "Base de datos: SQLite, Mysql, MongoDB.",
            "Plataforma en la nube: AWS.",
            "Protocolo: FTP.",
          ],
          desc2: [
            "Retraso en el diagnóstico y estrategia de tratamiento eficaz de los pacientes con ataque cardíaco.",
            "Nuestra aplicación reduce las tasas de pacientes con ataque cardíaco en el mundo y reduce los retrasos en el acceso a la atención hospitalaria y garantiza la provisión de tratamientos asequibles.",
          ],
        },
        img2: "/assets/images/casestudyinfo/stemi2.svg",
      },
    ],
  },
  {
    id: 13,
    img: "/assets/images/casestudies/grovo.svg",
    date: "Noviembre 2022",
    title: "Automatización de control de calidad para la plataforma de aprendizaje SAAS",
    service: ["Cloud & DevOps", "Software Testing"],
    industry: ["Retail"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/grovo1.svg",
        desc: "Grovo es una empresa de tecnología con sede en la ciudad de Nueva York que proporciona una plataforma de aprendizaje SaaS para mejorar y mejorar el rendimiento de los empleados y la organización. Grovo proporciona a las organizaciones una plataforma de microaprendizaje de software como servicio. Esta plataforma viene con una amplia gama de temas junto con un panel de control que tiene datos analíticos sobre el uso del contenido. Grovo atiende a las necesidades de aprendizaje y desarrollo de organizaciones de varias industrias utilizando diversas tecnologías.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Implementación de procesos sólidos de calidad en toda la organización.Diseño y ejecución de pruebas para garantizar una integración perfecta entre varios módulos de la plataforma de microaprendizaje. Diseño e implementación del framework de automatización de pruebas para la automatización de la ejecución de casos de prueba.",
          ],
          desc2: ["Selenium, Python, Jenkins, Mongo DB"],
        },
      },
    ],
  },
  {
    id: 14,
    img: "/assets/images/casestudies/mya.svg",
    date: "Septiembre 2022",
    title: "Proyecto de automatización de reclutamiento",
    service: ["Service Intergration"],
    industry: ["Retail"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/mya1.svg",
        img2: "/assets/images/casestudyinfo/mya2.svg",
        desc: "Mya Systems proporciona la plataforma líder de automatización de reclutamiento y IA conversacional que transforma el proceso de contratación al automatizar la divulgación y las comunicaciones a lo largo del ciclo de vida del candidato de extremo a extremo. Mya systems ha desarrollado una herramienta de reclutamiento de nueva generación con bots de chat habilitados para IA que interactúan con los candidatos en tiempo real, los examinan sobre las habilidades y la alineación de los requisitos del trabajo, y programan una entrevista si hay una coincidencia, realizan un seguimiento del historial de conversaciones y el estado de la solicitud del candidato.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle3: "Infraestructura y Tecnologías utilizadas",
        point: {
          desc1: [
            "Seis plataformas integradas de terceros.",
            "Casos de prueba automatizados integrados con las compilaciones de Circle-CI.",
            "Codificado para garantizar la privacidad de los datos, la seguridad de la configuración, la seguridad de la API REST HMAC y las validaciones eficientes de la carga útil de la conexión web.",
          ],
          desc3: ["Python, AWS, GIT"],
        },
      },
    ],
  },
  {
    id: 15,
    img: "/assets/images/casestudies/ussd.svg",
    date: "Octubre 2022",
    title:"Aplicación basada en USSD",
    service: ["Mobility"],
    industry: ["Retail"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/ussd1.svg",
        desc: "La aplicación CRG es un juego basado en la tecnología USSD y proporciona una plataforma para jugar incluso desde los teléfonos básicos con teclado y sin internet. El USSD proporciona una interfaz sencilla para acceder a la aplicación.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Arquitectura, Diseño de Aplicaciones, Desarrollo y Alojamiento",
            "Apoyo y mantenimiento del entorno de producción",
            "Ajuste de performance",
          ],
          desc2: ["USSD, Node JS, PostgreSQL"],
        },
      },
    ],
  },
  {
    id: 16,
    img: "/assets/images/casestudies/ios.svg",
    date: "Octubre 2022",
    title: "IOS y Android",
    service: ["Application Development"],
    industry: ["Retail"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/ios1.svg",
        desc: "La aplicación CRG es un juego basado en la tecnología USSD y proporciona una plataforma para jugar incluso desde los teléfonos básicos con teclado y sin internet. El USSD proporciona una interfaz sencilla para acceder a la aplicación.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Desarrollar y mantener aplicaciones móviles y para tabletas basadas en IOS y Android.",
            "Aplicaciones independientes con capacidad de almacenamiento de datos local.",
            "Desarrolla aplicaciones con integración de servidores.",
            "Desarrollo en Android nativo, Swift y multiplataforma.",
            "Desarrollo de aplicaciones basadas en USSD para admitir teléfonos con funciones básicas (teléfonos con teclado) con cualquier configuración.",
          ],
          desc2: ["Android nativo, Swift, multiplataforma, USSD, SQLite"],
        },
      },
    ],
  },
  {
    id: 17,
    img: "/assets/images/casestudies/thinkhr.svg",
    date: "Noviembre 2022",
    title: "Automatización de infraestructura e implementación",
    service: ["Cloud & DevOps"],
    industry: ["Retail"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/thinkhr1.svg",
        img2: "/assets/images/casestudyinfo/thinkhr2.svg",
        desc: "ThinkHR es una empresa de tecnología con sede en EE. UU. ThinkHR combina lo mejor en experiencia de asesores en vivo con tecnología innovadora y contenido integral e instructivo para ayudar a las organizaciones a abordar todos los riesgos potenciales de las personas, incluida la orientación sobre la preparación y gestión de esos riesgos.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Infraestructura y tecnologías utilizadas",
        subtitle3: "Planteamiento del Problema y la Solución",
        point: {
          desc1: [
            "Creación de infraestructura como código (a través de la terraforma en el entorno de la nube de AWS).",
            "Construcción e implementación continuas a través de terraform en AWS ECS Cluster.",
            "Creación de clústeres EKS en AWS.",
          ],
          desc2: [
            "Docker, terraform, AWS, GitHub, Route 53, AWS IAM, AmazonECS, Amazon EKS",
          ],
          desc3: [
            "Creación de infraestructura a través de terraform",
            "Documentación de los servicios de infraestructura",
            "Implementación continua a través del clúster de ECS mediante el uso de scripts terraform",
            "La solución fue la automatización de la infraestructura y la implementación y una mejor documentación y soporte.",
          ],
        },
      },
    ],
  },
  {
    id: 18,
    img: "/assets/images/casestudies/factoreal.svg",
    date: "Septiembre 2022",
    title:
      "Adopción de DevOps e implementación de pipelines de CI o CD",
    service: ["Application Development"],
    industry: ["Retail"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/factoreal1.svg",
        desc: "Factoreal es una plataforma de automatización de marketing para gestionar procesos de marketing repetitivos de manera eficiente. Esto ayuda a las empresas a gestionar todo el ciclo de vida de los canales de marketing de manera efectiva a través de varias plataformas de redes sociales digitales como Facebook, Twitter y LinkedIn.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Diseño y desarrollo de la integración de LinkedIn con Factoreal.",
            "Investigación sobre el soporte de LinkedIn para la integración con plataformas externas.",
          ],
          desc2: [
            "React, Kafka, PostgreSQL, Python, redis, nodo JS, Cassandra",
          ],
        },
      },
    ],
  },
  {
    id: 19,
    img: "/assets/images/casestudies/modeling.svg",
    date: "Agosto 2022",
    title: "Modelado e informes de BI para Charlotte Russe",
    service: ["Business Intelligence & Big Data Analysis"],
    industry: ["Retail"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/modeling1.svg",
        img2: "/assets/images/casestudyinfo/modeling2.svg",
        desc: "Charlotte Russe es una tienda minorista de ropa con sede en San Diego, California. Atienden las necesidades de moda de las mujeres en su adolescencia y veinte años y operan más de 550 tiendas en 45 estados de los Estados Unidos.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        subtitle2: "Planteamiento del Problema y la Solución",
        point: {
          desc1: [
            "Reestructuración arquitectónica y migración del modelo de datos existente y la plataforma de informes.",
            "Ayudar a construir un conjunto de data analytics y proporcionar análisis ad hoc al equipo de analytics.",
            "Proporcionar una solución analítica en algunos casos de estudio",
          ],
          desc2: [
            "Desarrollar y mantener el Data Mart; datos de vivienda que sirven a todas las necesidades y solicitudes de informes en toda la organización. Migrar y eliminar gradualmente la plataforma de residencia heredada en Oracle-Sap Hana a SQL Server - Power BI y SSRS. Mejorar el marco de informes existente mediante la unificación de los datos a través de diferentes fuentes y la creación de un sistema escalable para cualquier mejora adicional. La solución fue un repositorio de datos unificado, agrupando varias fuentes de información, una solución escalable con servicios de solicitud ad-hoc y un servicio de informes eficiente y rentable.",
          ],
          desc3: [
            "Experiencia en bases de datos y almacenamiento de datos: Oracle, SQL Server, Hive",
            "Soluciones ETL: SSIS, Scripting",
            "Modelado Analytics: R, Python",
            "Informes de inteligencia empresarial: PowerBI, SAP Webi, SSRS, ASP.NET",
          ],
        },
      },
    ],
  },
  {
    id: 20,
    img: "/assets/images/casestudies/flightops.svg",
    date: "Junio 2023",
    title: "Desarrollo de aplicaciones de operaciones de vuelo",
    service: [],
    industry: ["Travel & Transport Logistics"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/flightops1.svg",
        img2: "/assets/images/casestudyinfo/flightops2.svg",
        desc: [
          "Diseñado para aerolíneas, alianzas y grupos de aerolíneas. iFlight Ops optimiza las operaciones de vuelo para aumentar la eficiencia, reducir los costos y mejorar el rendimiento a tiempo. iFlight proporciona a los centros de control de operaciones de aerolíneas un conocimiento dinámico de la situación en tiempo real de las operaciones de vuelo de extremo a extremo. iFlight Ops permite la toma de decisiones proactiva y mejora la eficiencia operativa, el rendimiento a tiempo (OTP), reduce los costos de combustible a través de la optimización de la asignación de aeronaves y permite a todas las partes interesadas decidir y ejecutar de forma colaborativa la mejor estrategia de gestión de interrupciones.",
        ],
        subTitle1: "Desafío",
        subtitle2: "Servicios ofrecidos",
        subtitle3_half: "Tecnología ofrecida",
        subtitle3: "Solución",
        point: {
          desc1: [
            "Distorsiones de Gantt debido a problemas de caché del navegador",
            "Problema con RTU (Actualización en tiempo real)",
            "Problemas de lentitud/carga debido a que varios usuarios utilizan la aplicación a la vez",
            "La falta de conciencia sobre los formatos de procesamiento de mensajes bloquea la carga masiva de datos de vuelos",
          ],
          desc2: [
            "Evitar fallos en el cumplimiento operativo",
            "Mejorar la gestión del cambio de horario",
            "Reducir el impacto de las interrupciones operativas",
            "Mejorar la utilización de las aeronaves",
            "Mejorar la eficiencia del personal de operaciones",
          ],
          desc3_half: [
            "Desarrollo de aplicaciones web: Angular JS, JSP",
            "Desarrollo de API de aplicaciones: Java (Spring MVC), Spring Data JPA",
            "Base de datos: Oracle SQL y PostgreSQL",
          ],
          desc3: [
            "Usar un navegador web específico (por ejemplo, el modo de incógnito de Chrome).",
            "Aumentar el tamaño del servidor.",
            "Eliminar los datos no deseados o completados del servidor en intervalos regulares mediante ejecuciones de job automáticos.",
            "Agregar a los manuales de usuario, plantillas de muestra para evitar confusiones con respecto al procesamiento de mensajes.",
          ],
        },
      },
    ],
  },
  {
    id: 21,
    img: "/assets/images/casestudies/dataextract.svg",
    date: "Junio 2023",
    title:
      "Extracción de datos para integrar diferentes aplicaciones de aerolíneas",
    service: [],
    industry: ["Travel & Transport Logistics"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/dataextract1.svg",
        img2: "/assets/images/casestudyinfo/dataextract2.svg",
        desc: "El proyecto iFlight-data-extractor es una aplicación proxy que conecta el cliente de la aerolínea externa y la aplicación iFlight-ops. Es una aplicación de paso que llama a las operaciones de iFlight para obtener los datos requeridos y los devuelve al cliente.",
        subTitle1: "Desafío",
        subtitle2: "Servicios ofrecidos",
        subtitle3_half: "Tecnología ofrecida",
        subtitle3: "Solución",
        point: {
          desc1: [
            "Validación de la carga útil contra el esquema JSON",
            "Para el esquema JSON dado, necesitábamos validar la solicitud entrante json antes de llamar a end points requeridos.",
            "El desafío aquí fue que el validador json incorporado no era compatible con el rango de datos y la validación requerida",
          ],
          desc2: ["Desarrollo de APIs REST", "Integración del módulo de operaciones"],
          desc3_half: [
            "Desarrollo de aplicaciones web: Angular JS, JSP",
          "Desarrollo de aplicaciones API: Java (Spring MVC), Spring Data JPA",
            "Base de datos: Oracle SQL y Postgre SQL",
          ],
          desc3: [
            "Creamos un resolutor de argumentos de métodos de controlador personalizado para validar las cargas útiles JSON contra un esquema JSON.",
          ],
        },
      },
    ],
  },
  {
    id: 22,
    img: "/assets/images/casestudies/programtest.svg",
    date: "Junio 2023",
    title: "Aplicación de Pruebas de Programas para Operaciones de vuelo",
    service: [],
    industry: ["Travel & Transport Logistics"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/programtest1.svg",
        img2: "/assets/images/casestudyinfo/programtest2.svg",
        desc: "La cobertura de código de JUnit se refiere a la medición de cuanto del código fuente de una aplicación de software es cubierta por los casos de prueba escritos, usando el framework de JUnit. Este cuantifica la medida de cuánto de esté codigo es cubierto por las pruebas. Esto ayuda a identificar áreas del código que no han sido adecuadamente probadas o no probadas en lo absoluto. Mientras más alto sea el porcentaje de cobertura, más exhaustiva van a ser la pruebas en cuestión de ejecutar diferentes patrones de código, branches y condiciones.",
        subTitle1: "Desafío",
        subtitle2: "Servicio ofrecido",
        subtitle3: "Oferta de tecnología",
        point: {
          desc1: [
            "La base de código original que nos proporcionó IBS era una base de código heredada y, por lo tanto, escribir Junits para el código heredado fue un gran desafío porque los módulos del proyecto no estaban muy estructurados para escribir Junits.",
            "Había muchas clases existentes para las que teníamos que escribir los junits. Por lo tanto, comprender el archivo de clase escrito y su flujo de código y cubrirlos en junits dentro de las líneas de tiempo estipuladas fue un gran desafío.",
            "Dado que IBS tiene su propio framework(Sprout), escribir junits que se ajusten a este fue un desafío.",
          ],
          desc2: [
            "Operaciones de vuelo: mantenimiento de los horarios de vuelo, servicio de vuelo, mensaje sobre la actualización del vuelo, etc.",
            "Planificación y optimización de la tripulación: Mantenimiento de las actividades de la tripulación, como el empleo, el calendario de la lista, etc.",
            "Gestión de la tripulación: Mantener la licencia de la tripulación, el intercambio de servicios, el acceso a la pantalla, etc.",
          ],
          desc3: [
            "Desarrollo de aplicaciones web: Angular JS, Java, Maven, JSP",
            "Desarrollo de las aplicaciones API: Java (Spring MVC), Spring Data JPA, marco Sprout, Jacoco-maven-plugin",
            "Base de datos: Oracle SQL y Postgre SQL",
          ],
        },
      },
    ],
  },
  {
    id: 23,
    img: "/assets/images/casestudies/venom.svg",
    date: "Julio 2022",
    title: "Venom",
    service: [],
    industry: ["Satellite & Telecom"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/venom1.svg",
        desc: "Venom le permite virtualizar su red, tecnología de gestión de red que reduce los costos de tecnología y reduce la complejidad. Venom proporciona un camino para mejorar la gestión de la red, reducir los gastos de capital, la energía y los bienes raíces y minimizar la complejidad general",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Infraestructura y tecnología utilizadas",
        point: {
          desc1: [
            "Crea y supervisa redes virtuales, que consisten en una herramienta de dibujo de red basada en la web, un motor de servidor principal, indicadores clave de rendimiento y otras herramientas de monitoreo, etc.",
            "A alto nivel Venom tiene 3 componentes:",
            "Una que ejecuta una aplicación multiinquilino en la nube.",
            "El segundo consiste en Javascript que se ejecuta en el navegador, estos se utilizan para dibujar y administrar plantillas.",
            "El tercero es el Engine Host Manager o EHM. El EHM debe instalarse en cada servidor dentro de su clúster informático.",
          ],
          desc2: [
            "Python, MySQL, MongoDB, Kinetic JS, D 3 js, Open Stack, AWS, Selenium, Ansible",
          ],
        },
      },
    ],
  },
  {
    id: 24,
    img: "/assets/images/casestudies/tellabs.svg",
    date: "Junio 2022",
    title: "Mantenimiento y soporte de Tellabs",
    service: [],
    industry: ["Satellite & Telecom"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/tellabs1.svg",
        desc: "Venom le permite virtualizar su red, tecnología de gestión de red que reduce los costos de tecnología y reduce la complejidad. Venom proporciona un camino para mejorar la gestión de la red, reducir los gastos de capital, la energía y los bienes raíces y minimizar la complejidad general",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Soporte técnico",
        point: {
          desc1: [
            "Soporte de mantenimiento de red proactivo, incluida la instalación de software.",
            "Auditoría del rendimiento de la red",
            "Proporcionando mantenimiento de Tellabs y soporte las 24 horas del día, los 7 días de la semana en todo el mundo",
            "Proporcionar servicios de desarrollo para nuevas mejoras",
          ],
          desc2: [
            "Los servicios profesionales de Tellabs diseñan el guión para automatizar tareas tediosas pero críticas que podrían llevar a su personal horas completarse.",
            "Los expertos gestionan el proceso de actualización del software.",
            "Consultas de diseño de redes",
          ],
        },
      },
    ],
  },
  {
    id: 25,
    img: "/assets/images/casestudies/telecom.svg",
    date: "Agosto 2022",
    title: "Plataforma de comunicación de la red de telecomunicaciones",
    service: ["Software Testing"],
    industry: ["Satellite & Telecom"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/telecom1.svg",
        desc: "Viasat Inc es una empresa de comunicaciones con sede en Carlsbad, California, que ofrece servicios de banda ancha por satélite de alta velocidad. El modelo de carbono híbrido implica el uso tanto de la conexión por satélite como DSL o LTE.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Oferta técnica",
        point: {
          desc1: [
            "Prueba del cambio de datos de LLN como DSL a satélite.",
            "Validación de la conmutación por error del controlador de red.",
            "Servicio de monitoreo de la disponibilidad del servicio para todos los servicios internos de la red corporativa de Viasat.",
          ],
          desc2: [
            "Implementación del proceso de control de calidad Marco ágil",
            "Marco de automatización de pruebas Python, Robot Framework, Ansible scripting",
            "Base de datos MongoDB, DynamoDB",
          ],
        },
      },
    ],
  },
  {
    id: 26,
    img: "/assets/images/casestudies/webvcpe.svg",
    date: "Julio 2022",
    title:"Desarrollo de aplicaciones web vCPE",
    service: ["Application Development"],
    industry: ["Satellite & Telecom"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/webvpce1.svg",
        desc: "Un modelo de carbono híbrido vCPE implica el uso de la conexión de red por satélite y de baja latencia (DSL/LTE) desde el dispositivo UT. El satélite tiene un alto ancho de banda y alta latencia, mientras que el DSL/LTE tiene un ancho de banda bajo y alta latencia. Viasat proporciona a sus clientes una experiencia híbrida de alta latencia y alto rendimiento mediante el uso de diferentes rutas. Estamos trabajando con el equipo de automatización para ayudar a configurar y probar la red en general, así como para ayudar en el desarrollo de herramientas de prueba de velocidad y depuración para diferentes servicios en el nodo central utilizando varias tecnologías.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Integración e implementación continua.",
            "Desarrollo de automatización",
          ],
          desc2: [
            "Tecnologías utilizadas Ansible, Jenkins, Selenium Python, Go lang Python y Shell Scripting",
            "Base de datos MongoDB.",
            "Python, Jenkins, Selenium, Golang, Ansible, scripting de shell.",
          ],
        },
      },
    ],
  },
  {
    id: 27,
    img: "/assets/images/casestudies/carbon.svg",
    date: "Mayo 2022",
    title: "Aplicación móvil Android: Carbon",
    service: ["Mobility"],
    industry: ["Satellite & Telecom"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/carbon1.svg",
        desc: "Los clientes pueden iniciar sesión y comprobar la cantidad de datos que están utilizando mensual/diariamente. Incluso pueden establecer su límite de uso. Pueden ver las estadísticas de flujo de cada dispositivo en un formato gráfico.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Arquitecto, diseño de aplicaciones, desarrollo y alojamiento.",
            "Apoyo y mantenimiento del entorno de producción",
            "Ajuste del rendimiento",
          ],
          desc2: [
            "Tecnología Java, Android",
            "Preferencias compartidas de la base de datos",
            "Actualización del cliente de la API de descanso",
            "Java, Android, Retrofit",
          ],
        },
      },
    ],
  },
  {
    id: 28,
    img: "/assets/images/casestudies/cloudservices.svg",
    date: "Abril 2022",
    title: "Desarrollo de servicios en la nube",
    service: ["Cloud & DevOps"],
    industry: ["Satellite & Telecom"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/cloudservices1.svg",
        img2: "/assets/images/casestudyinfo/cloudservices2.svg",
        desc: "Securiti se dedica a mantener su plataforma en la nube a salvo de todo tipo de problemas de seguridad, proporcionando así un entorno seguro a nuestros clientes, dedicado a mantener su plataforma en la nube a salvo de todos los problemas, proporcionando así un entorno seguro a nuestros clientes. Securiti proporciona servicios de entorno multinube a varios clientes, construyendo la infraestructura a través de terraform en el entorno de nube múltiple asegurando los servicios.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Planteamiento del Problema y la Solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Gestionar y crear los servicios en las diversas nubes (AWS, GCP, Azure y Oracle)",
            "Construir y administrar la infraestructura como un código a través de terraform",
            "Ofrece seguridad en los datos de las aplicaciones de los clientes",
          ],
          desc3: [
            "Herramientas Terraform Bitbucket, Docker, Kubernetes, Microsoft visual studio code, Google cloud cli, azure cli.",
            "Amazon Web Services (AWS), Google Cloud Platform (GCP), Oracle Cloud Platform (OCP) Azure Cloud.",
          ],
          desc2: [
            "Implementar los servicios a través de terraform en la nube",
            "Solucionar y analizar los problemas en el entorno de la nube",
          ],
        },
      },
    ],
  },
  {
    id: 29,
    img: "/assets/images/casestudies/arti.svg",
    date: "Mayo 2022",
    title: "Desarrollo de Aplicación Web ARTI",
    service: ["Application Development"],
    industry: ["Satellite & Telecom"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/arti1.svg",
        img2: "/assets/images/casestudyinfo/arti2.svg",
        desc: "ARTI es una aplicación web propiedad de Epiphani. Esta aplicación será útil siempre que nos encontremos con algún problema relacionado con cualquier tema de investigación. Podemos plantear o publicar un problema creando una investigación utilizando esta aplicación. Quien conozca la solución puede unirse a la investigación y dar sugerencias sobre el problema de diferentes maneras, mediante texto, uso de comandos, libros de jugadas, flujos de trabajo, etc. Los participantes en una investigación también pueden chatear con Arti bot y discutir el problema en mención. usando comandos útiles.",
        subTitle1: "Compromiso de Data Template con el Cliente",
        subtitle2: "Planteamiento del Problema y la Solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: ["E-commerce.", "Compañías para Networking"],
          desc2: [
            "Arquitecto, Diseño, Desarrollo and Hosting de aplicaciones web en la nube.",
            "Implementación de Integración Contíuna (CI)",
            "Performance Tuning",
          ],
          desc3: [
            "Database: Dynamo",
            "UI: React JS, HTML5, CSS3",
            "Web Services: AWS",
            "Integración: AWS Amplify, AWS Cognito, GraphQL Google Map Services",
          ],
        },
      },
    ],
  },
  {
    id: 30,
    img: "/assets/images/casestudies/analytics.svg",
    date: "Enero 2022",
    title: "Inteligencia de Negocios BLMA  & Analytics",
    service: ["Business Intelligence & Big Data Analysis"],
    industry: ["Hospitality & Leisure"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/analytics1.svg",
        img2: "/assets/images/casestudyinfo/analytics2.svg",
        desc: "BLMA es un producto propiedad de Atarwa. Es una aplicación web que puede ser utilizada por diferentes tipos de entidades comerciales de fabricación, como medicina, alimentos, etc. La aplicación será útil para capturar los datos para el proceso de fabricación y se utilizará para el análisis (gráficos y gráficos). Esto ayudará a la entidad comercial a mejorar estos procesos de fabricación y la calidad del producto.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Planteamiento del Problema y la Solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Diseño, desarrollo e implementación de aplicaciones",
            "Sincronizar los datos de la aplicación con el servidor centralizado",
            "Monitoreo de análisis y generación de informes",
          ],
          desc3: [
            "Bases de datos: PostgreSQL",
            "Modelado de Analytics: Python, Angular8",
            "Servicios web: REST",
            "Interfaz De Usuario: Angular 8 with Bootstrap 4",
            "PostgreSQL, Java, Python, Angular8",
          ],
          desc2: [
            "Desarrollo de la interfaz de usuario para la recopilación de datos.",
            "Captura de datos para el proceso de fabricación.",
            "Muestra los datos del proceso de fabricación de cada artículo en forma de gráficos. La vista de resumen del elemento se mostrará como gráficos de caja y los gráficos individuales se mostrarán como gráficos de control con diferentes tipos de gráficos como gráficos individuales, rango móvil y gráficos de cambio de fase.",
            "Proporcione la opción de exportar gráficos de Analytics como informes en formato word/doc.",
            "Proporcione una opción de descarga para que los usuarios descarguen los informes creados.",
            "Informes personalizados para que los usuarios supervisen los datos",
            "Ayudará a mejorar la calidad y el proceso de fabricación basado en el análisis",
            "Servicio de informes eficiente y rentable",
          ],
        },
      },
    ],
  },
  {
    id: 31,
    img: "/assets/images/casestudies/devops.svg",
    date: "Enero 2022",
    title: "Adopción de DevOps en BLMA",
    service: ["Cloud & DevOps"],
    industry: ["Hospitality & Leisure"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/devops2.svg",
        img2: "/assets/images/casestudyinfo/devops1.svg",
        desc: "BLMA es un producto propiedad de Atarwa. Es una aplicación web que puede ser utilizada por diferentes tipos de entidades comerciales de fabricación, como medicina, alimentos, etc. La aplicación será útil para capturar los datos para el proceso de fabricación y se utilizará para el análisis (gráficos y gráficos). Esto ayudará a la entidad comercial a mejorar estos procesos de fabricación y la calidad del producto.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Planteamiento del Problema y la Solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Implementación de los servicios de infraestructura a través de los scripts terraform en el entorno en la nube de AWS.",
            "Configurar la integración continua y el despliegue continuo para el entorno de desarrollo/producción.",
          ],
          desc3: [
            "Amazon AWS, Terraform, Equilibradores de carga de aplicaciones, Autoscaling, Amazon RDS, S3,",
            "Amazon ECR, Amazon Certificate Manager, AWS IAM",
            "Jenkins, Docker, GitLab, Tomcat, Hashicorp Vault.",
          ],
          desc2: [
            "Proporcionar soporte de infraestructura de AWS",
            "Documentación de los servicios de infraestructura y proceso de construcción e implementación",
            "Activación y supervisión de las compilaciones en Jenkins",
            "Automatización del proceso de creación e implementación de la infraestructura",
            "Mejor documentación y soporte",
          ],
        },
      },
    ],
  },
  {
    id: 32,
    img: "/assets/images/casestudies/blmaweb.svg",
    date: "Enero 2022",
    title: "Desarrollo de aplicaciones web BLMA",
    service: ["Application Development"],
    industry: ["Hospitality & Leisure"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/blmaweb1.svg",
        img2: "/assets/images/casestudyinfo/blmaweb2.svg",
        desc: "BLMA es una aplicación web que puede ser utilizada por diferentes tipos de entidades comerciales de fabricación, como medicina, alimentos, etc. La aplicación será útil para capturar los datos para el proceso de fabricación y se utilizará para el análisis (gráficos y gráficos). Esto ayudará a la entidad comercial a mejorar estos procesos de fabricación y la calidad del producto",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Planteamiento del Problema y la Solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Diseño, desarrollo e implementación de aplicaciones",
            "Sincronizar los datos de la aplicación con el servidor centralizado",
          ],
          desc3: [
            "Base de datos: PgAdmin",
            "Servicios web: Descanso",
            "Interfaz de usuario: Angular 8 con Bootstrap 4",
          ],
          desc2: [
            "Desarrollo de la interfaz de usuario para la recopilación de datos",
            "Capturar los datos para el proceso de fabricación",
            "Monitoreo analítico",
            "Capturas de análisis en la generación de informes",
          ],
        },
      },
    ],
  },
  {
    id: 33,
    img: "/assets/images/casestudies/pantrycook.svg",
    date: "Febrero 2022",
    title: "Desarrollo de aplicaciones móviles de Pantry Cook",
    service: ["Mobility"],
    industry: ["Hospitality & Leisure"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/pantrycook1.svg",
        img2: "/assets/images/casestudyinfo/pantrycook2.svg",
        desc: "Pantry Cook es una despensa digital que gestiona los ingredientes del hogar y se conecta con los principales sitios de recetas para mostrar recetas para preparar platos con lo que se tenga. Pantry Cook elimina las dudas de qué hacer con todos los productos secos, parciales o las especias de su despensa. Con Pantry Cook uno puede organizar fácilmente los ingredientes en categorías para agilizar las búsquedas de recetas. Las recetas devueltas incluirán los ingredientes que se han agregado para la búsqueda, pero también pueden tener ingredientes adicionales necesarios.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Planteamiento del Problema y la Solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Diseño, desarrollo y verificación de aplicaciones",
            "Integración de un servicio de terceros que ayuda a buscar recetas de comida",
          ],
          desc3: [
            "Tecnología Front-end: React Js, React Native",
           "Tecnología Backend: PHP, Laravel",
            "Base de datos: MySQL",
            "Interfaz de usuario: React",
            "Servicios web: API REST",
            "Entorno de implementación: Servidor Ubuntu",
            "Bootstrap, React Native, HTML, CSS, MySQL, JQuery, PHP.",
          ],
          desc2: [
            "Diseño, desarrollo de la interfaz de usuario.",
            "Recopilación de datos de la API de terceros.",
            "Obtener datos de otros sitios web",
          ],
        },
      },
    ],
  },
  {
    id: 34,
    img: "/assets/images/casestudies/myroomie.svg",
    date: "Diciembre 2021",
    title: "Desarrollo de aplicaciones móviles",
    service: ["Mobility"],
    industry: ["Hospitality & Leisure"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/myroomie1.svg",
        img2: "/assets/images/casestudyinfo/myroomie2.svg",
        desc: "My Roomie es la primera empresa centrada en la vivienda estudiantil en la India que aborda una demanda masiva insatisfecha de alojamiento de alta calidad a diferencia de pg o albergue con todas las comodidades de hoy en día. El propósito de llevar a cabo este proyecto es crear una aplicación IOS para que MyRoomie Group rastree a los residentes y sus actividades.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Planteamiento del Problema y la Solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Gestionar a los residentes del albergue.",
            "Procesar pagos en línea para los residentes.",
            "Reportar problemas que enfrentan los residentes",
          ],
          desc3: [
            "Entorno de desarrollo - XCode y VSCode.",
            "Entorno de producción - TestFlight y Apple Appstore.",
            "React Native, Swift e IOS.",
          ],
          desc2: ["Solicitud de licencia.", "Solicitud de servicio.", "Solicitud de pago"],
        },
      },
    ],
  },
  {
    id: 35,
    img: "/assets/images/casestudies/neota.svg",
    date: "Noviembre 2021",
    title: "Aplicación Neota para eventos",
    service: ["Mobility"],
    industry: ["Hospitality & Leisure"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/neota1.svg",
        img2: "/assets/images/casestudyinfo/neota2.svg",
        desc: "Producto para la creación de eventos, invitación, etc. Gestión de amigos",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Arquitecto, diseño, desarrollo y pruebas",
            "Ajuste fino de rendimiento",
            "Publicación en Google Play Store y Apple App Store",
          ],
          desc3: [
            "IOS - Swift",
            "Android - Android nativo",
            "Base de datos local: SQLite",
          ],
        },
      },
    ],
  },
  {
    id: 36,
    img: "/assets/images/casestudies/voyawebapp.svg",
    date: "Octubre 2021",
    title: "Desarrollo de aplicaciones web de viajes",
    service: ["Application Development", "Mobility"],
    industry: ["Hospitality & Leisure"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/voya1.svg",
        img2: "/assets/images/casestudyinfo/voya2.svg",
        desc: "Voya proporciona a los usuarios su propio espacio para diseñar y crear su cartera de viajes con todos los detalles que quieran. Voya mantiene todos los viajes en una sola aplicación y permitirá a los usuarios crear, almacenar, compartir su información de viaje, fotos/vídeos/audio, itinerario, historia de viaje con sus conexiones.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Declaración del problema y solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Arquitecto, Diseño de Aplicaciones, Desarrollo y Alojamiento",
            "Integración continua para crear los build y el despliegue",
            "Integración con redes sociales",
          ],
          desc3: [
            "Base de datos: SQL Server",
            "Interfaz de usuario: HTML5, CSS, JQuery",
            "Servicios web: API REST",
            "Integración: Facebook, RSK Image Cropper, SDK, Fabric, Firebase, Crashlytics, Google Map Services",
          ],
          desc2: [
            "Arquitectura, diseño, desarrollo y alojamiento de la web en la nube.",
            "Configuración  de la integración continua",
            "Ajuste de performance",
          ],
        },
      },
    ],
  },
  {
    id: 37,
    img: "/assets/images/casestudies/manufacturing.svg",
    date: "Noviembre 2021",
    title: "Desarrollo de aplicaciones web",
    service: ["Application Development"],
    industry: ["Manufacturing"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/manufacturing1.svg",
        img2: "/assets/images/casestudyinfo/manufacturing2.svg",
        desc: "Godrej es una aplicación web propiedad de Vendor Globe. Esta aplicación se utiliza para el proceso de licitación para el proveedor de material. La aplicación se utiliza para procesos de licitación de extremo a extremo, como la selección del proveedor.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
             "Arquitectura, diseño, desarrollo y alojamiento de aplicaciones web en Linux",
            "Configuración de la integración continua",
            "Ajuste de performance",
          ],
          desc3: [
            "Base de datos MySQL",
            "UI HTML 5 CSS 3 JavaScript",
            "Encendedor de código backend (PHP)",
            "CodeIgniter, MySQL, javascript y HTML5",
          ],
        },
      },
    ],
  },
  {
    id: 38,
    img: "/assets/images/casestudies/handtracking.svg",
    date: "Diciembre 2021",
    title: "Algoritmo de seguimiento de movimientos manuales",
    service: ["Embedded & IoT", "Augmented Reality & Virtual Reality"],
    industry: ["Aerospace & Defence"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/handtracking1.svg",
        img2: "/assets/images/casestudyinfo/handtracking2.svg",
        desc: "El Leap Motion Controller es un módulo de rastreo de óptico de movimientos manuales que captura los estos movimientos con una precisión sin igual. Se puede usar junto con dispositivos de Realidad Virtual (VR) y Realidad Aumentada (AR) para permitirle sujetar objetos sin el uso de un controlador, creando así una experiencia más inmersiva, ya que se pueden usar las manos como normalmente se interactuaría con distintos objetos.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Algoritmo para enviar un conjunto de datos de mano.",
            "Aumentar el campo de visión.",
          ],
          desc3: ["Unity, C# (C-Sharp)"],
        },
      },
    ],
  },
  {
    id: 39,
    img: "/assets/images/casestudies/education.svg",
    date: "Septiembre 2021",
    title: "Whiteboard.Chat",
    service: ["Application Development"],
    industry: ["Education"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/education1.svg",
        img2: "/assets/images/casestudyinfo/education2.svg",
        desc: "Whiteboard.chat es una pizarra interactiva en línea gratuita para la enseñanza y la colaboración en equipo, creada por Epiphani Inc, una empresa con sede en San José, California. Whiteboard.chat es una pizarra virtual para profesores, estudiantes, colegas remotos, padres y niños para el aprendizaje colaborativo. Es de uso gratuito, conecta hasta 100 personas a un tablero simultáneamente y exporta tableros a PDF. También le permite observar y enseñar fácilmente a muchos tableros de usuarios al mismo tiempo.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Creación de contenido por grupos de educadores.",
            "Pizarra interactiva con tabletas gráficas.",
            "Contribuyendo a la comunidad whiteboard.chat.",
            "Una increíble pizarra en línea para enseñar.",
            "Las pizarras interactivas colaborativas pueden ayudar con el aprendizaje en línea.",
          ],
          desc3: [
            "Tecnologías utilizadas para este proyecto - React JS, AWS tech stack - como AWS amplify, Cognito, lambda, Amazon S3.",
          ],
        },
      },
    ],
  },
  {
    id: 40,
    img: "/assets/images/casestudies/propertydata.svg",
    date: "Agosto 2021",
    title: "API para Información de Propiedades",
    service: ["Software Testing"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/property1.svg",
        img2: "/assets/images/casestudyinfo/property2.svg",
        desc: "La API de datos de propiedad (interfaz de programa de aplicación) de ATTOM es una plataforma de autoservicio que impulsa a varias industrias con datos de propiedades premium. Su plataforma de API de propiedades consolida los datos de impuestos, escrituras, hipotecas y ejecuciones hipotecarias centrados en la propiedad con datos mejorados de vecindario, escuela, delincuencia y comunidad.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Planteamiento del Problema y la Solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Desarrollo de las API REST",
            "Integración continua utilizando Azure Pipelines.",
            "Rendimiento y validación de datos.",
            "Visualización de datos y supervisión de aplicaciones",
          ],
          desc3: [
            "Base de datos MSSQL My SQL, Web Services Azure, VSTS, Red Hat Gestión de API a escala 3",
            "MySQL, Dot net core, Angular, Node JS, PHP, React Native, servidor Microsoft SQL",
          ],
          desc2: [
            "Gestión de API",
            "Nuestra plataforma de API de propiedades consolida los datos de impuestos, escrituras, hipotecas y ejecuciones hipotecarias centrados en la propiedad con datos mejorados de vecindario, escuela, delincuencia y comunidad",
          ],
        },
      },
    ],
  },
  {
    id: 41,
    img: "/assets/images/casestudies/azure.svg",
    date: "Agosto 2021",
    title: "Arquitectura serverless de Azure (Sin servidor)",
    service: ["Business Intelligence & Big Data Analysis"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/azure1.svg",
        img2: "/assets/images/casestudyinfo/azure2.svg",
        desc: "ClosingCorp es el proveedor líder de datos y tecnología de costos de cierre de bienes raíces residenciales para las industrias de servicios hipotecarios y inmobiliarios. ClosingCorp optimiza los procesos y servicios de cierre para prestamistas hipotecarios, empresas de títulos y liquidación y profesionales de bienes raíces.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Planteamiento del Problema y la Solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Construir un almacén de datos centralizado para analizar la actividad comercial.",
            "Migrar las bases de datos heredadas existentes con la base de datos SQL de Azure recientemente creada.",
          ],
          desc3: [
            "DEV, PROD, SQL Server 2017, Azure Cloud, SSIS, SSRS, PoweBI",
            "SQL, Java script, sales force, Power BI",
          ],
          desc2: [
            "El proyecto de migración de datos de ClosingCorp es para obtener datos de los sistemas existentes, asignándolos al nuevo sistema mediante la aplicación de los nuevos requisitos de datos de ClosingCorp. El mapeo de datos (o la falta de ellos) resaltará las transformaciones necesarias, así como las actividades de limpieza necesarias para garantizar que el nuevo sistema se abastezca con la última data transformados.",
            "La solución fue un almacén de datos centralizado y data arts enfocados, una solución escalable con servicios de solicitud ad hoc y un servicio de informes eficiente y rentable",
          ],
        },
      },
    ],
  },
  {
    id: 42,
    img: "/assets/images/casestudies/shorenstein.svg",
    date: "Septiembre 2021",
    title: "Migración de datos e informes para organizaciones inmobiliarias",
    service: ["Business Intelligence & Big Data Analysis"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/shorenstein1.svg",
        img2: "/assets/images/casestudyinfo/shorenstein2.svg",
        desc: "Shorenstein es una de las organizaciones inmobiliarias más antiguas y respetadas del país que opera a nivel nacional en la propiedad y operación de propiedades de oficinas de alta calidad. Son una empresa de inversión inmobiliaria totalmente integrada, que aporta una visión empresarial a las propiedades de oficinas y de uso mixto en mercados dinámicos de los Estados Unidos.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Planteamiento del Problema y la Solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Migrar datos de múltiples sistemas heredados a la aplicación Yardi Voyager.",
            "Implementar nuevos informes de modificación existentes para apoyar las actividades diarias de la organización.",
          ],
          desc3: [
          "Mainframe de IBM, archivo XLS en unidad compartida, base de datos de SQL Server y SSIS, informes SSRS",
            "Servidor Microsoft SQL, MongoDB, SSRS, ASP.NET",
          ],
          desc2: [
            "La organización decide implementar la aplicación Yardi Voyager para cumplir con sus actividades operativas y financieras diarias. Los datos de los sistemas heredados tuvieron que migrarse al nuevo sistema. Migrar datos de los sistemas heredados a la plataforma Yardi Voyager. Crear nuevos informes para apoyar las actividades diarias.",
            "La solución fueron los datos heredados disponibles en el sistema centralizado para las actividades diarias de la organización. Informes personalizados para la alta dirección. Reducción de costes en el mantenimiento de aplicaciones heredadas.",
          ],
        },
      },
    ],
  },
  {
    id: 43,
    img: "/assets/images/casestudies/lres.svg",
    date: "Junio 2021",
    title:
      "Implementación y automatización del proceso de control de calidad para bienes raíces",
    service: ["Application Development", "Software Testing"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/lres1.svg",
        img2: "/assets/images/casestudyinfo/lres2.svg",
        desc: "LRES Corporation es una empresa nacional de gestión de tasación, que ofrece un conjunto completo de servicios inmobiliarios residenciales y comerciales. Gestionan una red nacional de proveedores, tasadores, agentes, corredores e inspectores para entregar valoraciones, gestión de activos de REO y soluciones HOA a la industria hipotecaria y inmobiliaria.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Declaración del problema y solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Implementación de procesos sólidos de calidad en toda la organización.",
            "Diseñe y ejecute pruebas para garantizar que la calidad de los entregables esté en su mejor momento.",
            "Diseñe e implemente un marco de automatización de pruebas para automatizar la ejecución del caso de prueba en la nube.",
          ],
          desc3: [
            "DEV, UAT, PROD, Azure Cloud, . NET Core 3.1, . NET framework 4.7.1, SQL Server 2017",
            "Katalon, Microsoft SQL Server, Microsoft Azure.",
          ],
          desc2: [
            "Los asociados obtenían las órdenes pendientes y enviaban manualmente correos con recordatorios. Los dashboard de ordenes, clientes, proveedores, productos no cargaban rapidamente. Se requería de la verificación manual de las licencias de los proveedores.",
            "La solución fue obtener información de la orden, cliente, proveedor, producto para que sus respectivos Dashboards carguen rápidamente. El mejoramiento del proceso de asignamiento de horas se basaría en la ubicación del proyecto. Se pensó en una integración muy particular y personalizada para con el sistema OMS web.",
          ],
        },
      },
    ],
  },
  {
    id: 44,
    img: "/assets/images/casestudies/calque.svg",
    date: "Julio 2021",
    title: "Aplicación web de propiedades e hipotecas",
    service: ["Application Development"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/calque1.svg",
        img2: "/assets/images/casestudyinfo/calque2.svg",
        desc: "Calque es una aplicación web utilizada para administrar la información del prestatario, de la propiedad y la hipoteca que proporcionará garantía por un período particular en el que se puede vender la casa actual de los clientes. Hay dos tipos de roles de usuario, es decir, persona que ingresa los datos del cliente y el underwriter quien usa la aplicación para llenar la solicitud y para aprobar o rechazar la misma después de revisarla.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Planteamiento del Problema y la Solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Desarrollar y hacer pruebas de calidad de todas las funcionalidades del administrador y del portal web del cliente final. ",
          ],
          desc3: [
            "Proveedor de la nube: AWS,",
            "Desarrollo de aplicaciones web: ReactJS",
            "Desarrollo de la API de aplicaciones: Python",
            "Base de datos Postgre: SQL",
          ],
          desc2: [
            "El proceso de compra y venta de una casa no está optimizado para los consumidores, deben programar el proceso justo para vender antes de comprar o incurrir en costos adicionales, lo que hace que este proceso consuma tiempo innecesariamente, a la vez que es estresante y costoso.",
            "La solución fue la capacidad de gestionar y manejar las aplicaciones de los clientes para proporcionar garantía. Se manejó una lógica de cálculos complejos para probar una matriz basada en reglas para elegir una cierta duración de la oferta basada en las condiciones del mercado inmobiliario el cual a su vez estaba basado en datos estadísticos.",
          ],
        },
      },
    ],
  },
  {
    id: 45,
    img: "/assets/images/casestudies/sunflowerbank.svg",
    date: "Junio 2021",
    title: "Optimización y análisis de datos",
    service: ["Business Intelligence & Big Data Analysis", "Software Testing"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/sunflowerbank1.svg",
        img2: "/assets/images/casestudyinfo/sunflowerbank2.svg",
        desc: "Sunflower Bank es un banco con sede en EE. UU. que ofrece diferentes préstamos: personales, de pequeñas empresas, comerciales, de gestión de patrimonio, tarjetas de crédito, hipotecarios y más.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Planteamiento del Problema y la Solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Data Template apoyó al equipo de negocios e ingeniería existente del cliente con conjunto de queries y procedimientos almacenados para optimizar los datos y el proceso.",
          ],
          desc3: ["Visual Studio, Azure DevOps, Git, Microsoft SQL Server"],
          desc2: [
            "Los clientes contaban un conjunto de queries y procedimientos almacenados y necesitaban un desarrollador y un recurso de QA de Data Template para apoyar al equipo de ingeniería y negocios existente. El alcance cubriría principalmente el ajuste fino, la mejora y la comprobación del código cargado al repositorio y la ejecución. Los recursos de Data Template también ayudarían a crear archivos CSV para pasarlos al equipo de negocios de Sunflower para un análisis adicional.",
            "Data Template fue compatible con el equipo de oficina para optimizar los procedimientos almacenados y de consulta existentes para la generación de informes de Excel y el trabajo de paquetes SSIS.",
          ],
        },
      },
    ],
  },
  {
    id: 46,
    img: "/assets/images/casestudies/bktechouse.svg",
    date: "Octubre 2022",
    title: "Automatización de la implementación y adopción de DevOps",
    service: ["Cloud & DevOps"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/bktechouse1.svg",
        img2: "/assets/images/casestudyinfo/bktechouse2.svg",
        desc: "BK Techouse es una empresa de tecnología dedicada a deleitar a sus clientes, empleados y accionistas al proporcionar productos y servicios de tecnología innovadora de alta calidad que permiten a sus clientes esforzarse en un mercado que cambia rápidamente.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Adopción de DevOps, implementando los pipelines de CI/CD de BK Techouse para sus aplicaciones existentes. Formación del equipo técnico de BK Tech en mejores prácticas.",
          ],
          desc3: ["Docker, GitLab, NGINX, PostrgeSQL"],
          desc2: [
            "Automatización de la implementación.",
            "Incorporación de las mejores prácticas de DevOps.",
          ],
        },
      },
    ],
  },
  {
    id: 47,
    img: "/assets/images/casestudies/intertrust.svg",
    date: "Mayo 2021",
    title: "Portal para inversores",
    service: "",
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/intertrust1.svg",
        img2: "/assets/images/casestudyinfo/intertrust2.svg",
        desc: "Intertrust es una empresa de fideicomiso internacional y gestión corporativa que cotiza en bolsa con sede en Ámsterdam, Países Bajos, conocida por sus servicios fiduciarios, que incluyen impuestos, fideicomisos y gestión empresarial.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Planteamiento del Problema y la Solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Migración de código.",
            "Convertir procedimiento almacenados en cube js.",
            "Fuentes de datos widget usando cube js.",
          ],
          desc3: ["Dot NET core, React JS, Azure SQL"],
          desc2: [
            "Seguridad, contacto y administrador de fondos como parte del boletín, seleccione el archivo y distribúyalo a los clientes (inversor, contacto y administrador de fondos) como parte de la función del boletín. Integración de RBAC para la presentación de informes. Migración de datos.",
            "Los informes web existentes carecían el rol de persona de contacto en su lugar para ser creados como inversor.",
            "Lo mismo debería resolverse en el nuevo enfoque. Mover los datos existentes de la base de datos POV y Web Reporting a la base de datos Azure SQL.",
          ],
        },
      },
    ],
  },
  {
    id: 48,
    img: "/assets/images/casestudies/firstamerican.svg",
    date: "Mayo 2021",
    title: "Arquitectura serverless de AWS (Sin servidor)",
    service: ["Business Intelligence & Big Data Analysis"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/firstamerican1.svg",
        img2: "/assets/images/casestudyinfo/firstamerican2.svg",
        desc: "First American Corporation. First American Financial Corporation es una empresa de servicios financieros de los Estados Unidos y es un proveedor líder de servicios de seguros y liquidación de títulos para las industrias de bienes raíces e hipotecas.",
        subTitle1: "Compromiso de Data Template con el Cliente",
        subtitle2: "Planteamiento del Problema y la Solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Construir un Data Warehouse creado internamente para eliminar la dependencia de los servicios externos de terceros.",
            "Integrar los sistemas existentes con las API recién desarrolladas para integrarse con el DataWarehouse.",
          ],
          desc3: [
            "Experiencia en bases de datos y almacenamiento de datos: DynamoDB, MongoDB",
            "Extracción de datos: NodeJS",
            "API REST: AWS API Gateway",
          ],
          desc2: [
            "El primer equipo de American Mortgage confiaba en Fraud Guard para obtener la información de la propiedad.",
            "Esto le estaba costando al equipo una cantidad considerable de dinero anualmente.",
            "Se propuso un nuevo sistema para extraer y almacenar datos de XML y crear un almacén de datos.",
            "Extracción de datos de archivos XML usando Node.JS y almacenamiento en DynamoDB",
            "Construyendo API de nodos para integrarse con el Data Warehouse.",
            "Repositorio de datos unificado bajo un solo paraguas",
            "Solución escalable con servicios de solicitud ad-hoc",
            "Servicio de informes eficiente y rentable",
          ],
        },
      },
    ],
  },
  {
    id: 49,
    img: "/assets/images/casestudies/leadsherpa.svg",
    date: "Abril 2021",
    title: "Campañas de SMS y tarjetas postales",
    service: ["Application Development"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/leadsherpa1.svg",
        img2: "/assets/images/casestudyinfo/leadsherpa2.svg",
        desc: "Lead Sherpa™ es una empresa que ha estado resolviendo desafíos de marketing inmobiliario desde 2018. Todo comenzó cuando Lead Sherpa creó el primer servicio de mensajería de texto compatible con TCPA, ahora hay múltiples características como la construcción de un conjunto de generación de clientes potenciales, la obtención de clientes potenciales y herramientas de seguimiento de campañas, como los mensajes de texto y el envío de tarjetas postales.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Planteamiento del Problema y la Solución",
        subtitle3:"Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "SkipTrace",
            "Campañas de SMS",
            "Campañas de tarjetas postales",
            "Opciones de búsqueda avanzada",
          ],
          desc3: [
            "Tecnología de backend : Python Django Rest framework , Celery",
            "Tecnología de front-end: React Js",
            "Herramienta de automatización: Cypress tool para la automatización",
            "Herramienta DevOps: Linode, circleci, docker compose y Sentry.",
          ],
          desc2: [
            "Se reemplazó el mecanismo de suscripción existente utilizando braintreey su nueva pasarela de pago.",
            "Se han añadido más mejoras a las campañas existentes como el correo directo, más opciones de filtro para la búsqueda y también la estabilización del sistema mediante la corrección de errores.",
         "Se agregó más cobertura de prueba al sistema y se automatizaron funcionalidades de la aplicación existente utilizando la cypress tool.",
            "Introdujimos el proceso de despliege para la aplicación Front End.",
          ],
        },
      },
    ],
  },
  {
    id: 50,
    img: "/assets/images/casestudies/springboardauto.svg",
    date: "Marzo 2021",
    title: "Integración del servicio SpringboardAuto",
    service: ["Application Development"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/springboardauto1.svg",
        img2: "/assets/images/casestudyinfo/springboardauto2.svg",
        desc: "SpringboardAuto es una plataforma de préstamos para automóviles directamente al consumidor que transforma la compra y la financiación de automóviles en una experiencia moderna en línea y móvil. Los consumidores disfrutan de una transacción en línea de autoservicio, transparente y fácil ",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Declaración del problema y solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Solución de arquitectura para el desarrollo de servicios",
            "Integración del servicio de terceros que ayuda en el flujo de trabajo automatizado del proceso de aprobación del préstamo.",
            "Desarrollo del servicio de Windows para automatizar la activación por correo.",
          ],
          desc3: [
            "Base de datos: SQL Server",
            "Servicios web: .NET Servicios web, Node JS",
            "Integración: Meridian Link (loan Origination System), Equifax, CarzInspection, Kelly",
            "Blue Book, Fedex, Skye Closing, Twilio, Docusign, Mail Chimp.",
          ],
          desc2: [
            "Arquitectura, diseño, desarrollo y alojamiento de aplicaciones web en la nube",
            "Configurar la integración continua (CI)",
            "Ajuste de performance",
          ],
        },
      },
    ],
  },

  {
    id: 51,
    img: "/assets/images/casestudies/realtycare.svg",
    date: "Abril 2021",
    title: "Aplicación móvil y web",
    service: ["Application Development", "Mobility"],
    industry: "Finance, Mortgage & Real Estate",
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/realtycare1.svg",
        img2: "/assets/images/casestudyinfo/realtycare2.svg",
        desc: "Realty care proporciona a los administradores de propiedades funcionalidades para actualizar el mantenimiento y otras actividades de cada propiedad, y proporcionar un mejor servicio a los propietarios e inquilinos.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Arquitectura, Diseño de Aplicaciones, Desarrollo",
            "Soporte y mantenimiento del entorno de producción",
            "Ajuste de performance",
          ],
          desc3: ["Tecnología: Python, django, Angular, PostgreSQL"],
        },
      },
    ],
  },
  {
    id: 52,
    img: "/assets/images/casestudies/zondaipad.svg",
    date: "Marzo 2021",
    title: "Desarrollo de aplicaciones de Zonda Ipad Salesforce",
    service: ["Application Development"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/zonda1.svg",
        img2: "/assets/images/casestudyinfo/zonda2.svg",
        desc: "Zonda ofrece una forma amigable e intuititva para acceder a datos en tiempo real a lo largo de los Estados Unidos con mapas detallados, data entendible, interpreativa, narrativa y entrega información clave del mercado inmobiliario en un formato amigable de una forma muy fácil. Almacena y monitorea puntos clave para identificar oportunidades. Descube tendencias en proyectos aledaños como tasas de absorción mensual, inventarios, lotes disponibles, así como información demográfica importante para los compradores de viviendas, tal como escuelas, tasas criminales y condiciones del mercado laboral.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subTitle2: "Declaración del problema y solución",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "Arquitectura, diseño de aplicaciones, desarrollo y alojamiento",
            "Apoyo y mantenimiento del entorno de producción",
            "Ajuste de performance",
          ],
          desc2: [
            "Arquitectura, diseño, desarrollo y alojamiento de la en la nube.",
            "Configurar la integración continua",
            "Ajuste de performance",
          ],
          desc3: [
            "UI Visual Force, HTML 5 CSS, JavaScript",
            "Plataforma Salesforce com, Apex, Triggers, Automatización empresarial mediante procesos",
            "constructores y flujos de trabajo",
            "Servicios web API REST",
            "Integración de los servicios de Google Map",
            "Entorno de implementación Salesforce com",
          ],
        },
      },
    ],
  },
  {
    id: 53,
    img: "/assets/images/casestudies/assetmanagement.svg",
    date: "Febrero 2021",
    title: "Internet de equipos terrestres \"Gestión de activos\"",
    service: ["Embedded & IoT"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/assetmanagement1.svg",
        img2: "/assets/images/casestudyinfo/assetmanagement2.svg",
        desc: "El aumento en el negocio de alquiler de equipos pesados en todo el mundo. La ubicación remota, las condiciones de funcionamiento y los modelos de negocio de alquiler comenzaron a crear nuevos desafíos para los OEM. Con el fin de abordar estos desafíos al tiempo que permiten a las agencias de alquiler estar en contacto constante con los equipos y, por lo tanto, empoderar a los negocios de muchas maneras, OEM decidió adoptar la última tecnología \"inteligente y conectada\".",
        subTitle1: "Solución",
        subtitle3: "Beneficios",
        point: {
          desc1: [
            "Data Template trabajó con el cliente para el diseño y desarrollo de plataformas de hardware y software para soluciones conectadas según los requisitos del OEM.",
            "Diseño de desarrollo de la interfaz del sensor y del sistema de adquisición de datos.",
            "Habilitación de la nube utilizando tecnologías avanzadas de satélite y celulares",
            "Verificación y validación del sistema ECU HW SW",
            "ECU envía datos en tiempo real y permite al usuario mantenerse informado todo el tiempo. El usuario puede acceder a estos datos a través de SMS, correo electrónico o por enlace web.",
            "ECU recopila información importante sobre las máquinas, como su salud, ubicación y muchos otros datos vitales a través de sensores.",
            "Estos datos se transmiten a un servidor seguro y desde allí se envían al usuario. El usuario puede usar estos datos para tomar decisiones críticas sobre su negocio o la máquina.",
          ],
          desc3: [
            "Al monitorear las horas de la máquina con precisión, el sistema garantiza que el mantenimiento se mantenga actualizado.",
            "Las alertas le mantienen al tanto de las máquinas que necesitan mantenimiento de rutina.",
            "Alertas críticas de máquinas, seguimiento de equipos.",
            "Permite demostrar el historial de servicio de cualquier máquina que sea vital para una buena reventa.",
          ],
        },
      },
    ],
  },
  {
    id: 54,
    img: "/assets/images/casestudies/internetofuv.svg",
    date: "Enero 2021",
    title:
      "Internet para un sistema de curamiento UV \"Mantenimiento predictivo\"",
    service: ["Embedded & IoT"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/internetofuv1.svg",
        img2: "/assets/images/casestudyinfo/internetofuv2.svg",
        desc: "En las líneas de producción en masa es muy esencial predecir el estado del equipo en servicio. Este enfoque de mantenimiento predictivo promete un ahorro de costes en comparación con el mantenimiento preventivo rutinario o basado en el tiempo. Para abordar esto, el cliente decidió interactuar con expertos que articularían la solución de extremo a extremo de una manera contextual y los ayudarían a desarrollar un \"Sistema de Monitoreo de Información Avanzada\".",
        subTitle1: "Adquisición de datos",
        subtitle3: "Desarrollo de aplicaciones e implementación en la nube",
        point: {
          desc1: [
            "Desarrollo de la interfaz del sensor.",
            "Desarrollo de firmware de autocontrol",
            "Diseño de protocolo propietario e implementación sobre el bus industrial",
          ],
          desc3: [
            "Desarrollo de sockets web, algoritmo de análisis de integración (IP del cliente) PC, web, desarrollo de aplicaciones móviles, perfiles de usuario y desarrollo de pantallas.",
            "Flujo fluido de datos entre la red local, el back office y el teléfono inteligente, diagnóstico, información disponible con el técnico en el teléfono inteligente, actualización remota del firmware y actualizaciones.",
          ],
        },
      },
    ],
  },
  {
    id: 55,
    img: "/assets/images/casestudies/dled.svg",
    date: "Febrero 2021",
    title: "Desarrollo de aplicaciones web DLED",
    service: ["Embedded & IoT"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/dled1.svg",
        img2: "/assets/images/casestudyinfo/dled2.svg",
        desc: "El propósito de llevar a cabo este proyecto es obtener datos en tiempo real de los sensores y mostrar todos los parámetros como temperatura, CO2 humedad, conductividad eléctrica y mostrar los mismos en la interfaz gráfica de usuario de la aplicación.",
        subTitle1: "Compromiso de Data Template con el cliente",
        subtitle2: "Tarea y solución presentadas",
        subtitle3:"Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            "El usuario podrá cultivar sus cultivos dentro de un entorno controlado.",
            "La aplicación mostrará todos los parámetros en tiempo real junto con el ciclo de riego de los cultivos, que son esenciales para un mejor crecimiento de las plantas.",
            "El usuario debe ser capaz de controlar todos los parámetros de forma remota para un mejor crecimiento del cultivo.",
          ],
          desc2: [
            "Los cultivos se cultivan dentro de una incubadora donde están conectados los sensores",
            "Estos sensores junto con el motor de luz están conectados a la placa Arduino. La placa Arduino está conectada a Raspberry Pi 4, que tiene un módulo WIFI para transferir los datos en tiempo real de los sensores a la aplicación.",
            "El usuario es creado por el administrador y el usuario obtiene las credenciales en su correo",
            "El usuario puede iniciar sesión con sus credenciales y supervisar todos los parámetros de forma remota a través de la aplicación. El usuario puede operar en modo automático o manual. En el modo Automático, el usuario establece un rango para todos los parámetros y los mismos se mantienen en la incubadora. En el modo manual, el usuario puede cambiar el valor de cada parámetro manualmente mediante los controles deslizantes atmosféricos y los controles deslizantes de nivel de luz proporcionados en la aplicación.",
            "La Raspberry Pi enviará solicitudes al servidor con todos los valores de los datos del sensor en tiempo real y el servidor las comparará con los valores establecidos por el usuario y enviará la respuesta adecuada a Raspberry Pi.",
            "La Raspberry Pi envía instrucciones a la placa Arduino para cambiar los valores de los parámetros cuando los valores no cumplen con el rango permitido. El usuario también puede ver el gráfico de la variación de cada parámetro durante el período de cultivo.",
          ],
          desc3: ["Python", "React", "postgresql"],
        },
      },
    ],
  },
  {
    id: 56,
    img: "/assets/images/casestudies/nft.svg",
    date: "Enero 2021",
    title: "Desarrollo de NFT Marketplace",
    service: ["NFT & Blockchain"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/nft1.svg",
        img2: "/assets/images/casestudyinfo/nft2.svg",
        desc: "El mercado NFT, como su nombre lo indica, es una plataforma descentralizada donde los usuarios pueden crear, comprar, vender y almacenar tokens no fungibles. El mercado de NFT facilita el minteo y el comercio de NFT a escala global, mientras que la blockchain como su tecnología subyacente garantiza la transparencia y el registro inmutable del proceso de tokenización y comercio de activos digitales.",
        subTitle1: "Las siguientes características se pueden crear para el mercado de NFT",
        subtitle3: "Infraestructura y tecnologías utilizadas",
        point: {
          desc1: [
            <span style={{ fontWeight: "400", color: "#121212" }}>
              Registrarse/Iniciar sesión
            </span>,
            "Para comprar o vender en la plataforma, un usuario debe poder crear una cuenta. El proceso de registro se puede lograr fácilmente a través de números de teléfono, correos electrónicos o cuentas de redes sociales. Después de eso, se les pide a los usuarios que indiquen su cuenta de criptomonedas para las transacciones. Sin embargo, si no tienen uno, pueden registrarse a través de la billetera Coinbase, Metamask u otras billeteras criptográficas.",
            <span style={{ fontWeight: "400", color: "#121212" }}>
              Comercio
            </span>,
            "El mercado de NFT debe tener una tienda que ofrezca a los usuarios toda la información necesaria para un artículo: ofertas, propietarios, vistas previas, descripción general de las operaciones o historial de precios.",
            <span style={{ fontWeight: "400", color: "#121212" }}>
              Filtros
            </span>,
            "Usando filtros, se vuelve más fácil navegar por un sitio, especialmente si planeas construir un lugar de mercado para muchos objetos de colección. Al agregar la función de filtros, los usuarios pueden seleccionar artículos por método de pago, estado de la lista, categoría y colección.",
            <span style={{ fontWeight: "400", color: "#121212" }}>
              Búsqueda de artículos
            </span>,
            "Una plataforma de mercado NFT debe admitir el etiquetado y la gestión de categorías para permitir a los usuarios buscar objetos de colección. Utilice una barra de búsqueda en el sitio y agregue categorías.",
            <span style={{ fontWeight: "400", color: "#121212" }}>
              Crear listados
            </span>,
            "Un usuario debe ser capaz de crear y enviar coleccionables. Usando esta función, un usuario debe cargar archivos y rellenar la información del token, como el nombre, las etiquetas y la descripción.",
            <span style={{ fontWeight: "400", color: "#121212" }}>
              Comprar y Subastar
            </span>,
            "La plataforma de mercado NFT debería tener una función que permita a los usuarios comprar y subastar los NFT que figuran en la plataforma. La función de puja debe incluir una fecha de vencimiento de la oferta y permitir a los usuarios ver detalles sobre el estado actual de la oferta.",
            <span style={{ fontWeight: "400", color: "#121212" }}>Billetera</span>,
            "La plataforma NFT Marketplace debe tener una cartera que permita a los usuarios almacenar, enviar y recibir tokens no fungibles. La forma más fácil de integrar esta función es proporcionar a los usuarios una billetera conectada que ya utilizan. Por ejemplo, puedes integrar las carteras más populares como Metamask, Coinbase o cualquier billetera criptográfica.",
          ],
          desc3: [
            "Solidity (contratos inteligentes)",
            "React",
            "Node js",
            "Estándares de NFT",
            "Sistema de Archivos InterPlanetary (IPFS)",
            "Trufa",
            "Ganache",
          ],
        }, 
      },
    ],
  },

  {
    id: 57,
    img: "/assets/images/casestudies/aibased.svg",
    date: "Diciembre 2020",
    title: "Sistema de elusión de colisiones basado en IA",
    service: ["Machine Learning & Artificial Intelligence"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/aibased1.svg",
        img2: "/assets/images/casestudyinfo/aibased2.svg",
        desc: "Desarrollar un sistema de evitación de colisiones totalmente autónomo basado en IA para buques no tripulados, eliminación de errores/intervención humana para una navegación segura, reducción de la mano de obra, reducción de costos, evitar la dependencia de los fabricantes de equipos originales extranjeros para tecnología/repuestos/servicios especializados y construir un expertise en tecnología especializada.",
        subTitle1: "Tarea a realizarse",
        subtitle2: "Solución a plantearse y Desafíos",
        subtitle3_half: "Infraestructura y tecnologías utilizadas",
        subtitle3: "Características y funcionalidades del producto",
        subtitle5: "Rentabilidad",
        point: {
          desc1: [
            "La creciente densidad del flujo de tráfico de buques conduce a la frecuente ocurrencia de accidentes de colisión de barcos. Las colisiones de barcos causan grandes pérdidas en términos de propiedad, equipo y vidas humanas. Por lo tanto, se deben centrar más investigaciones en este problema, que es el resultado principal de un error humano durante el control del barco. A medida que las actividades económicas mundiales se vuelven más interconectadas, la densidad de los flujos de tráfico marítimo está aumentando, especialmente en las zonas de navegación costera y pesca. Claramente, esta situación aumenta el riesgo de colisiones entre buques. Las colisiones de barcos a menudo resultan en bajas significativas y daños económicos. Se ha estimado que el error humano es responsable de entre el 75 % y el 96 % de todos los accidentes marítimos. Según se informa, algunas colisiones son causadas por que los miembros de la tripulación son consumidos con los instrumentos de a bordo, lo que significa que no pueden ver lo que está sucediendo en los mares en el exterior.",
            "Las fuerzas navales están introduciendo buques autónomos en todo el mundo para muchas funciones de seguridad. Estos buques realizan un trabajo monótono y necesitan navegar las 24 horas del día en el mar sin ningún incidente/accidente. Si se introduce el evitamiento de colisiones en dichos buques, esto dará un gran alivio a la tripulación a bordo evitando arduas labores 24X7. El error humano se puede reducir mientras se navega por los buques en el mar y en el puerto. Este sistema autónomo, una vez desarrollado, se puede integrar tanto en buques tripulados como no tripulados. El sistema de IA que se está desarrollando debe ser capaz de evitar colisiones y estar entrenado para evitar accidentes en el mar o en el puerto. Una colisión/accidente en el mar sería desastrosa para la pérdida de hombres/material de la Marina o incluso del propio barco. El sistema de IA que se está desarrollando debe enseñarse con las reglas de navegación en el mar, es decir, (Regla de la carretera-ROR). Una vez que el sistema de evitación de colisiones siga a ROR, el barco no cometerá ninguna colisión. Es necesario integrar los sensores/radares/AIS y los controles de navegación de los barcos existentes en el sistema de IA que se está desarrollando para que el sistema sea totalmente autónomo.",
            "Even with strict regulations for the training and qualification of seafarers, many a time the crew gets panicked and forgets the duties during such emergencies So if we use AI then it takes comparatively less time than humans. And also humans get nervous in critical situations but machines will perform whatever action is required automatically.",
          ],
          desc2: [
            "El sistema de IA que se está desarrollando para evitar colisiones en el mar debe enseñarse con el ROR marítimo existente, como se muestra a continuación. Los sensores instalados a bordo se pueden utilizar para localizar obstrucciones alrededor de los buques. El sistema de IA que se está desarrollando debe integrarse con el radar de navegación y el AIS de los buques para capturar imágenes de radar y posiciones de otros buques en línea. Una vez que se identifican los buques de otros buques, el sistema determina la probabilidad de colisión a partir de su velocidad y curso. Una vez que se determina la probabilidad de colisión, se calcula el curso futuro del barco. A partir del curso futuro de los buques, se determinan las posibilidades de colisión y, en consecuencia, se aplica el algoritmo ROR para negociar la situación. El cambio de rumbo y el comando del acelerador se transmiten a los controles de la nave para evitar la colisión en un modo autónomo o también se puede emitir una advertencia adecuada al personal del barco para evitar la colisión.",
            "El sistema debe ser capaz de detectar objetos y evitar colisiones que son una amenaza para los barcos como otros barcos, olas enormes, etc. Lo que sucede es que en la parte superior del barco habrá un radar. Usando ese radar, encontraremos si hay algún objeto presente en esa dirección, este es un enfoque tradicional. El sistema de IA propuesto utilizará ese radar si recibimos alguna señal de los alrededores, entonces la IA captura automáticamente las imágenes en la que recibimos la señal. Una vez más, tenemos que pasar las imágenes capturadas al algoritmo, entonces resultará en qué tipo de objeto está presente y solo estamos capturando la imagen cuando el radar encuentra las señales. Y de la manera tradicional, la visibilidad del barco es pobre debido a la niebla, la lluvia o la oscuridad. En ese caso, la IA captura la imagen y hace algo de filtración para obtener una visión clara. Si bien hay un potencial significativo para que la IA mejore la seguridad al reducir los \"errores humanos\" en las operaciones marítimas, también hay muchos aspectos de la tecnología que sugieren nuevos peligros.",
          ],
          desc3_half: [
            "Captura de imágenes de radar, procesamiento de señales, mejoramiento de imágenes, procesamiento de imágenes, reconocimiento de buques, reconocimiento de objetivos, posición de predicción de futuro, GPU de centro de datos, IA débil/estrecha, aprendizaje de refuerzo, aprendizaje de supervisión ML en ROR, biblioteca y creación de conjuntos de datos en recipientes",
            "Python, Open CV, FastAPI, Keras y Tensorflow, PostgreSQL",
          ],
          desc3: [
            "El producto que se está desarrollando será una solución basada en la IA estrecha para evitar cursos de colisión y colisiones con otros buques en el mar. Una vez interconectados con el sistema de navegación del puente y el AIS, los buques que se acercan al buque del usuario se determinan junto con su curso futuro. El ROR será seguido por un sistema de IA para evitar colisiones y se dará una advertencia adecuada a todos los interesados. El radar y el AIS deben proporcionar imágenes continuamente al sistema de IA que se está desarrollando para determinar el movimiento de los buques y calcular la probabilidad de colisión con respecto a la posición y la velocidad/curso del buque naval.",
          ],
          desc5: [
            "Si el sistema de IA se desarrolla para tomar la salida en forma de imágenes de los sistemas AIS y de radar en un modo en línea, las mismas imágenes también se pueden procesar continuamente. Como cierta, la posición de los buques/obstrucción alrededor y para determinar la probabilidad de colisión y la evitación correspondiente. Aquí, la Marina puede ahorrar una gran cantidad al no recrear sensores/radares dedicados para el sistema anticolisión basado en IA desarrollado. Se puede capturar capturas de pantalla de alta resolución del radar/lidar y el ASI existentes. Este enfoque no incurrirá en ningún coste para la creación de prototipos.",
          ],
        },
      },
    ],
  },
];
export const newsimg = [
  {
    img: "/assets/images/newsevents/srinivasagupta.svg",
    title: "December 2024",
    subTitle:
      "Had an amazing meeting with Mr. Srinivasa Gupta, Senior Director and Head of Go-To-Market Sales at Hitachi Digital Services, Japan, during his visit to our Data Template Bangalore office today!",
  },
  {
    img: "/assets/images/newsevents/mrzech.svg",
    title: "December 2024",
    subTitle:
      "It was a privilege to host Mr. Zech K, Technology Sales at Beeks Group, Glasgow, Scotland at our Data Template office in Mangalore.",
  },
  {
    img: "/assets/images/newsevents/tieglobal.svg",
    title: "December 2024",
    subTitle:
      "Data Template participated in the TiE Global Summit 2024, and showcased our expertise in various IT services to a wide range of audience. Thank you TiE Global organizers for inviting us.",
  },
  {
    img: "/assets/images/newsevents/christiankronseder.svg",
    title: "October 2024",
    subTitle:
      "Today it was great to catch up with Christian Kronseder, CTO at Envizage, London. Stay tuned for more updates on Envizage and Data Template alliance!",
  },
  {
    img: "/assets/images/newsevents/disarmco.svg",
    title: "October 2024",
    subTitle:
      "Data Template and Disarmco, London to work together in defence , energy and other sectors.",
  },
  {
    img: "/assets/images/newsevents/newjersey.svg",
    title: "October 2024",
    subTitle:
      "New Jersey, US based Fintech Company and Data Template to embark on developing AI based platform - Financially Well Being !!",
  },
  {
    img: "/assets/images/newsevents/alliehenderson.svg",
    title: "October 2024",
    subTitle:
      "It was wonderful to catch up with Allie Henderson and Hannah Mickelson at Optomi, Atlanta, US further strengthening the tie-up between Optomi and Data Template.",
  },
  {
    img: "/assets/images/newsevents/orionloss.svg",
    title: "October 2024",
    subTitle:
      "Zein Investment Astana, Kazakhstan to partner with Data Template to provide technology services in the healthcare domain.",
  },
  {
    img: "/assets/images/newsevents/kazakhstan.svg",
    title: "October 2024",
    subTitle:
      "Data Template Founder and CEO Anil Parakkad met with Ambassador of India to Republic of Kazakhstan Dr. T V Nagendra Prasad, IFS at Embassy of India in Astana, Kazakhstan and discussed ways to enhance strategic collaboration and cooperation and other areas of common interest in the Information Technology sector in Kazakhstan.",
  },
  {
    img: "/assets/images/newsevents/jamaica.svg",
    title: "September 2024",
    subTitle:
      "Data Template and Jamaica based Innovate10x to collaborate in delivering Cloud and Enterprise application development.",
  },
  {
    img: "/assets/images/newsevents/koshidacorporation.svg",
    title: "September 2024",
    subTitle:
      "Orion Loss Adjusters Limited, Jamaica and Data Template to build enterprise and AI platform to transform Orion’s business digitally.",
  },
  {
    img: "/assets/images/newsevents/premiumbespoke.svg",
    title: "September 2024",
    subTitle:
      "Premium bespoke suit tailoring company London House based in Lima to engage Data Template in developing AI powered solutions.",
  },
  {
    img: "/assets/images/newsevents/neosseguridad.svg",
    title: "September 2024",
    subTitle:
      "Data Template embarks on providing software development services for Neos Seguridad, Lima, Peru.",
  },
  {
    img: "/assets/images/newsevents/lima.svg",
    title: "September 2024",
    subTitle:
      "Lima, Peru based RTM Consulting engages Data Template to provide technology services in the energy and mining, finance and manufacturing sector.",
  },
  {
    img: "/assets/images/newsevents/atlanta.svg",
    title: "September 2024",
    subTitle:
      "Atlanta, US based Optomi LLC and Data Template to collaborate and deliver technology services across various industries which includes insurance, retail, logistics etc.",
  },
  {
    img: "/assets/images/newsevents/krishnavhs.svg",
    title: "September 2024",
    subTitle:
      "We had the pleasure of engaging with Mr. Krishna VHS, Co-Founder and Head of Sales & Strategy from vBridge Hub, during his visit to our Data Template office in Bangalore.",
  },
  {
    img: "/assets/images/newsevents/vivekcariappa.svg",
    title: "August 2024",
    subTitle:
      "It was great to host Mr. Vivek Cariappa, Founder of Krac-A-Dawna at our Bangalore office.",
  },
  {
    img: "/assets/images/newsevents/koshidacorporation1.svg",
    title: "August 2024",
    subTitle:
      "Koshida Corporation, Tokyo, Japan and Data Template to work together in providing IT solutions for iOT, Business Intelligence and Analytics.",
  },
  {
    img: "/assets/images/newsevents/kimaruai.svg",
    title: "August 2024",
    subTitle:
      "It was wonderful to catch up with the Kimaru.ai team in Tokyo, Japan. Data Template to further strengthen tie-up with Kimaru.ai to provide cutting edge, innovative AI enabled solutions across varied industries globally.",
  },
  {
    img: "/assets/images/newsevents/crystalventure.svg",
    title: "August 2024",
    subTitle:
      "Data Template to partner with Crystal Ventures Kigali, Rwanda to provide technology services in the field of IT infra, Cloud and Security.",
  },
  {
    img: "",
    title: "",
    subTitle:
""  },
];

export const eventsimg = [
  {
    img: "/assets/images/newsevents/townhall.svg",
    title: "Town Hall Meeting 2024",
    subTitle:
      "What an incredible Town Hall meeting at our Bangalore and Mangalore offices! Our CEO, Anil Parakkad, delivered powerful insights, inspiring us all to think bigger and bolder. The energy in the room was palpable as we discussed innovation, collaboration, and the exciting future ahead at @datatemplate !.",
  },
  {
    img: "/assets/images/newsevents/christmas.svg",
    title: "Celebrating Christmas Day 2024",
    subTitle:
      "From Secret Santa to many Fun activities, this season has brought joy and camaraderie to our workplace.",
  },
  {
    img: "/assets/images/newsevents/narayanahealth.svg",
    title: "Health & Wellness Drive By Narayana Health Team 2024",
    subTitle:
      "We are extremely happy to have the Narayana Health team at our Bangalore office for a valuable health assessment event!",
  },
  {
    img: "/assets/images/newsevents/karnatakarajyotsava.svg",
    title: "Celebrating Karnataka Rajyotsava 2024",
    subTitle:
      "Celebrating Karnataka Rajyotsava at our office, we embrace the unity in diversity that creates the vibrant tapestry of our state's rich cultures and traditions.",
  },
  {
    img: "/assets/images/newsevents/hackathon.svg",
    title: "Hackathon 2024",
    subTitle:
      "It was an exciting and fun filled day at Data Template on our annual Hackathon event this week! Creativity, Imagination, Collaboration and Coding were seen expanding the horizon at the event.",
  },
  {
    img: "/assets/images/newsevents/independenceDay.svg",
    title: "Independence Day 2024",
    subTitle:
      "Celebrated Independence Day at our offices with creative desk decorations and vibrant tricolor attire! Proud of our team's spirit and unity.",
  },
  {
    img: "/assets/images/newsevents/mrandmsdt.svg",
    title: "Mr. & Ms. Data Template 2024",
    subTitle:
      "Step into the fun of yesterday's Mr. & Ms. Data Template event with these snapshots! It was a day of smiles, energy, and great teamwork. Check out the memories we made!",
  },
  {
    img: "/assets/images/newsevents/womenEmpowermentSession2024.svg",
    title: "Women Empowerment Session 2024",
    subTitle:
      "Empowered and inspired! Huge thanks to Dr. Priyanka Kiran for an incredible women empowerment session at our office yesterday!",
  },
];

export const testimonials_imgs = [
  {
    img: "/assets/images/Testimonials/DoriDanganhardt.svg",
    title: "Cheif Data Offier, Closing Corp",
    subTitle: "Dori Daganhardt",
  },
  {
    img: "/assets/images/Testimonials/Baldridge.svg",
    title: "Director of Research, The Feinberg School of Medicine",
    subTitle: "Abi Baldridge",
  },
  {
    img: "/assets/images/Testimonials/Dr.Ajay.svg",
    title: "Deputy Director & Head, Health System Uni",
    subTitle: "Dr. Ajay S Vamadevan",
  },
  {
    img: "/assets/images/Testimonials/SonaMahtani.svg",
    title: "Cheif Executive Selby Trust, London",
    subTitle: "Sona Mahtani",
  },
  {
    img: "/assets/images/Testimonials/Lorem(1).svg",
    title: "Lorem Ipsum",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "/assets/images/Testimonials/Lorem(2).svg",
    title: "Lorem Ipsum",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "/assets/images/Testimonials/Lorem(3).svg",
    title: "Lorem Ipsum",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "/assets/images/Testimonials/Lorem(4).svg",
    title: "Lorem Ipsum",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "/assets/images/Testimonials/Lorem(5).svg",
    title: "Lorem Ipsum",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "/assets/images/Testimonials/Lorem(6).svg",
    title: "Lorem Ipsum",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "/assets/images/Testimonials/Lorem(7).svg",
    title: "Lorem Ipsum",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "/assets/images/Testimonials/Lorem(8).svg",
    title: "Lorem Ipsum",
    subTitle: "Lorem Ipsum",
  },
];
export const ai_imgs = [
  {
    id: "ethereum",
    img: "/assets/images/blog/ethereum.svg",
    title: "January 2025",
    subTitle: "Ethereum Network for Real-time Data Processing",
  },
  {
    id: "collaborative",
    img: "/assets/images/blog/Collaborative.svg",
    title: "October 2022 ",
    subTitle: "Collaborative Machine Learning",
  },
  {
    id: "augmentedintelligence",
    img: "/assets/images/blog/BusinessIntelligence.svg",
    title: "July 2022",
    subTitle:
      "Enhancing Business Performance with Augmented Business Intelligence",
  },
]
export const blog_imgs = [
  // {
  //   id: "chatbot",
  //   img: "/assets/images/blog/Chatbot.svg",
  //   title: "August 2024",
  //   subTitle: "Implementing Chatbot using Rasa Pro Framework ",
  // },
  {
    id: "ruleengine",
    img: "/assets/images/blog/ruleengine.svg",
    title: "November 2024",
    subTitle: "Rule Engine",
  },
  {
    id: "sqlquery",
    img: "/assets/images/blog/Sqlquery1.svg",
    title: "August 2024",
    subTitle: "Mastering SQL Query Optimization ",
  },
  {
    id: "web",
    img: "/assets/images/blog/web.svg",
    title: "Enero 2023",
    subTitle: "¿La Web 3.0 vale la pena la emoción?",
  },
  {
    id: "hrteamops",
    img: "/assets/images/blog/hr.svg",
    title: "Enero 2023 ",
    subTitle: "Recursos Humanos: Ingeniero de TeamOps",
  },
  {
    id: "gaming",
    img: "/assets/images/blog/Gaming.svg",
    title: "Septiembre 2022",
    subTitle:
      "El mercado de juegos generará $USD 150 mil millones en acuerdos y recaudación de fondos.",
  },
  {
    id: "enterprise",
    img: "/assets/images/blog/Enterprise.svg",
    title: "Agosto 2022",
    subTitle: "La serie de AI Empresarial: Productos / Casos de Uso",
  },
  {
    id: "biometrics",
    img: "/assets/images/blog/Innovations.svg",
    title: "Junio 2022",
    subTitle:
      "Las innovaciones en tecnología hacen que nuestras vidas sean más seguras, desde la biometría hasta la IA",
  },
  {
    id: "pycharm",
    img: "/assets/images/blog/Pycharm.svg",
    title: "Mayo 2022",
    subTitle:
      "PYCHARM- El IDE más potente y fácil de usar para el desarrollo de Python",
  },
  {
    id: "blockchain",
    img: "/assets/images/blog/Blockchain.svg",
    title: "Abril 2022 ",
    subTitle: "Cómo la industria del juego blockchain está en auge a pesar de los desafíos",
  },
  {
    id: "cryptocurrency",
    img: "/assets/images/blog/Cryptocurrency.svg",
    title: "Marzo 2022",
    subTitle: "El malware de minería de criptomonedas se dirige a AWS Lambda",
  },
];

export const benefits_imgs = [
  {
    img: "/assets/images/benefits/Flexible.svg",
    title: "Trabajo Flexible",
  },
  {
    img: "/assets/images/benefits/Social.svg",
    title: "Club Social",
  },
  {
    img: "/assets/images/benefits/Health.svg",
    title: "Seguro de Salud",
  },
  {
    img: "/assets/images/benefits/Competitive.svg",
    title: "Salarios y Bonos Competitivos",
  },
  {
    img: "/assets/images/benefits/Teaontap.svg",
    title: "Hora del Te",
  },
  {
    img: "/assets/images/benefits/Funatwork.svg",
    title: "Diversión en el Trabajo",
  },
  {
    img: "/assets/images/benefits/Skill.svg",
    title: "Programas de Ascensos",
  },
  {
    img: "/assets/images/benefits/Inter.svg",
    title: "After Office",
  },
  {
    img: "/assets/images/benefits/Niche.svg",
    title: "Experiencia interactiva en Tecnología Especializada",
  },
  {
    img: "/assets/images/benefits/Empowerment.svg",
    title: "Enfocados en el empoderamiento de Empleados",
  },
  {
    img: "/assets/images/benefits/Crossplatform.svg",
    title: "Oportunidades de entrenamiento en multi plataformas",
  },
];

export const westandtogether_imgs = [
  {
    img: "/assets/images/westandtogether/westandtogether.svg",
    title: "Nos unimos contra el odio",
    subTitle:
      "Nos mantenemos unidos contra cualquier ataque de odio dirigido a alguien en nuestra red de oficinas por motivos de raza, religión, orientación sexual, discapacidad o identidad de género.",
  },
  {
    img: "/assets/images/westandtogether/womencouncil.svg",
    title: "Consejo de Mujeres",
    subTitle:
      "El concepto del Consejo de Mujeres es un amplio compromiso de cambiar todos los aspectos de la vida para crear una vida más armoniosa y feliz para las mujeres. Medimos y actuamos continuamente sobre el progreso para crear igualdad de género. La igualdad de género es un derecho humano básico y también es fundamental para tener un mundo pacífico y próspero.",
  },
  {
    img: "/assets/images/westandtogether/diversity.svg",
    title: "Programas de diversidad y liderazgo",
    subTitle:
      "El programa Diversidad en el Liderazgo tiene como objetivo desafiar los prejuicios, eliminar obstáculos para alcanzar roles de liderazgo y utilizar herramientas para generar confianza en los aspirantes a líderes. Está dirigido a aspirantes a líderes, altos dirigentes, gerentes y gobernadores. Trabajamos en estrecha colaboración con People Element para identificar y orientar a nuestros líderes.",
  },
  {
    img: "/assets/images/westandtogether/hunger.svg",
    title: "Hambre y educación",
    subTitle:
      "Como empresa progresista, creemos firmemente en nuestros compromisos sociales. Estamos orgullosos de trabajar estrechamente con Karunya Bangalore, una organización sin fines de lucro con la visión de servir a los pobres y preservar a la humanidad.",
  },
  {
    img: "/assets/images/westandtogether/iamwhat.svg",
    title: "Soy lo que soy",
    subTitle:
      "Siempre fomentamos la igualdad en el lugar de trabajo, ya sea su orientación sexual o identidad de género. Hemos incluido con orgullo la orientación sexual y la identidad de género en nuestras políticas de no discriminación como una forma de tener un compromiso público de contratar más empleados lesbianas, gays, bisexuales, transgénero y queer (LGBTQ).",
  },
  {
    img: "/assets/images/westandtogether/health.svg",
    title: "Salud y Bienestar",
    subTitle:
      "Hemos construido una cultura de bienestar integral que incluye lo físico, emocional, financiero, social, profesional, comunitario y de propósito. En el centro de esto está la creciente necesidad de flexibilidad en cuanto a dónde, cuándo y cómo trabajan los empleados.",
  },
];

export const StratergyAlliences = [
  {
    id: "envizage",
    img: "/assets/images/strategicalliance/Envizage.svg",
    title: "Transforming financial futures",
    subTitle: "Envizage",
  },
  {
    id: "indigital",
    img: "/assets/images/strategicalliance/indigital.svg",
    title: "Leading edge digital transformation",
    subTitle: "INDIGITAL Inc",
  },
  {
    id: "yourgrantagency",
    img: "/assets/images/strategicalliance/yourGrantAgency.svg",
    title: "Matching SMEs with the right grants",
    subTitle: "YourGrant Agency",
  },
  {
    id: "whiteboard",
    img: "/assets/images/strategicalliance/whiteboard.svg",
    title: "Connect, collaborate & share",
    subTitle: "Whiteboard.chat",
  },
  {
    id: "intersystems",
    img: "/assets/images/strategicalliance/intersystem.svg",
    title: "Creative data technology ",
    subTitle: "InterSystems",
  },
  {
    id: "yellowpage",
    img: "/assets/images/strategicalliance/yellowpage.svg",
    title: "Proudly Kazakhstan’s B2B information system",
    subTitle: "Yellow Pages Kazakhstan",
  },
  {
    id: "ozone",
    img: "/assets/images/strategicalliance/ozone.svg",
    title: "Enterprise solution for manufacturing",
    subTitle: "Ozone",
  },
  {
    id: "astra",
    img: "/assets/images/strategicalliance/astra.svg",
    title: "Aerospace and Defence",
    subTitle: "Astra",
  },
  {
    id: "clinally",
    img: "/assets/images/strategicalliance/clinally.svg",
    title: "AI powered healthcare system ",
    subTitle: "ClinAlly",
  },
  // {
  //   id:'cybershelter',
  //   img: '/assets/images/strategicalliance/cybershelter.svg',
  //   title: 'Security & reliability assured',
  //   subTitle: "CyberShelter"
  // }
];

export const certification_imgs = [
  {
    img: "assets/images/certification/certification1.svg",
  },
  {
    img: "assets/images/certification/certification2.svg",
  },
  {
    img: "assets/images/certification/certification3.svg",
  },
  {
    img: "assets/images/certification/certification4.svg",
  },
];

// export const MA_imgs = [{
//   img: 'assets/images/Certification_imgs/MA(1).svg'
// },
// {
//   img: 'assets/images/Certification_imgs/MA(2).svg'
// },
// {
//   img: 'assets/images/Certification_imgs/MA(3).svg'
// }
// ]

export const ourteam = [
  {
    id: "founder",
    img: "/assets/images/ourteam/akbar.svg",
    title: "Lider Técnico en Código Abierto",
    subTitle: "Akbar Ali",
    subTitle2:
      "Data Template ha sido muy deliberado a la hora de comprender mis objetivos profesionales y trabajar conmigo para alcanzarlos. Llevo casi 4 años en la empresa y durante este tiempo tuve un tremendo crecimiento personal y profesional.",
  },
  {
    id: "finance",
    img: "/assets/images/ourteam/sajin.svg",
    title: "Arquitecto Asociado en Tecnología .Net",
    subTitle: "Sajin Sivanandhan",
    subTitle2:
      "Mi experiencia general con la empresa durante los últimos 5 años trabajando allí ha sido excelente. La mejor parte del liderazgo en Data Template es la capacidad de ayudar no solo a alcanzar los objetivos de la empresa sino también los míos propios. He podido crecer profesional y personalmente y he desarrollado amistades y relaciones significativas.",
  },

  {
    id: "tech",
    img: "/assets/images/ourteam/bdteams.svg",
    title: "Desarrollo de Negocios",
    subTitle: "Kimi, Anjali, Dipika & Supriya",
    subTitle2:
      "El equipo de DN se centra en crear valor a largo plazo para la empresa. Ayuda a la empresa a crecer y desarrollarse de forma plausible y beneficiosa. Son la cara de la empresa y ayudan en el desarrollo del negocio. En Data Template estamos encantados de tenerlos a todos a bordo con nosotros.",
  },
  {
    id: "techno",
    img: "/assets/images/ourteam/hrrecruitersupdate.svg",
    title: "Reclutamiento RRHH",
    subTitle: "Suhali, Apurva Kumari & Patil Laxmi Mallikarjun",
    subTitle2:
      "El equipo de contratación beneficia a la empresa al lograr los objetivos establecidos y fomentar la unidad y la comprensión del equipo. El equipo de reclutamiento busca encontrar y contratar candidatos para cualquier puesto específico requerido por la empresa. ¡Encuentran a la persona adecuada para el perfil adecuado! La familia de Data Template aprecia su arduo trabajo.",
  },
];

export const LocationData = [
  {
    country: "Bangalore",
  },
  {
    country: "Cincinnati",
  },
  {
    country: "Mangalore",
  },
  {
    country: "London",
  },
  {
    country: "Almaty",
  },
  {
    country: "Dhaka",
  },
  {
    country: "Vilnius",
  },
  {
    country: "Kyiv",
  },
];

export const wpData = [
  {
    envUrl: "https://www.datatemplate.com/",
    blogLink: "http://65.1.194.170/category/blog/",
    postsLink: "http://65.1.194.170/wp-json/wp/v2/posts?_embed&per_page=100",
    categoriesLink:
      "http://65.1.194.170/index.php?rest_route=/wp/v2/categories",
    contact: "http://65.1.194.170/contact.php",
    career: "http://65.1.194.170/career.php",
    news: "http://65.1.194.170/news.php",
    blogApi:
      "https://www.googleapis.com/blogger/v3/blogs/1881004714709103954/posts?maxResults=50&key=AIzaSyBgEPFAuNxd2pcIf2rKWO22AEUwVPWJ57o",
  },
];

export const aiconsultingimgs = [
  {
    id: 1,
    img: "/assets/images/aiservicescasestudyinfo/enterpriseautomated.svg",
    date: "September 2024",
    title: "Enterprise Automated Communication Solution",
    service: [],
    industry: ["Travel & Transport Logistics"],
    drilldown: [
      {
        img1: "/assets/images/aiservicescasestudyinfo/enterpriseautomated1.svg",
        desc: [
          "Develop an analytical system that scrapes and interprets text from web pages and PDFs, converts this information into embeddings for storage in a vector database, and utilizes a chatbot integrated with a semantic search API to provide specific responses in the banking and healthcare sectors.",
          "Developed and deployed an AI Chatbot using LLM’s and word embeddings ,which process the user queries and promptly provides accurate and timely information. The detailed process on how the objective was achieved is as follows.",
        ],
        subTitle1: "Solution Benefits",
        subtitle2: "Technology",
        point: {
          desc1: [
            "Automated Content Extraction: Streamlines the process of converting web content and PDFs into digital format, saving users time and effort by automating data retrieval and storage.",
            "Enhanced Data Understanding: Utilizes advanced embedding techniques and semantic search to analyze and interpret complex banking and healthcare information, providing users with clear, actionable insights.",
           "Instant Responses: Offers real-time answers to user inquiries, drastically reducing wait times compared to conventional research or customer support channels.", 
           "Accuracy and Relevance: Minimizes the spread of outdated or incorrect information by sourcing data directly from relevant documents, ensuring users receive the most current and accurate answers.", 
           "User-Centric Experience: Features an intuitive user interface that simplifies the search for specific banking and healthcare information, enhancing user satisfaction and engagement.",
           "Round-the-Clock Availability: Provides constant access to information, enabling users to find answers to their questions anytime, enhancing convenience and user satisfaction.", 
          "Personalized Responses: Adapts answers to each user's unique query by analyzing their questions in the context of the vast data stored in the vector database, ensuring highly relevant and customized information.", 
           "Scalable Solution: Designed to efficiently handle growing numbers of users and queries without significant increases in cost or decreases in performance.", 
          "Informed Decision-Making: Empowers users by providing them with comprehensive insights into banking and healthcare questions, aiding them in making well-informed decisions.", 
           "Educational Impact: Enhances users' understanding of complex topics in banking and healthcare, contributing to improved financial and health literacy.",
          ],
          desc2: [
             "Dolly 2.0",
             "Llama-2", 
             "Google Palm", 
             "ReactJS",
             "PyCharm", 
             "Chroma DB", 
             "Python",
             "Flask", 
             "VS Code",
          ],
        },
      },
    ],
  },
  {
    id: 2,
    img: "/assets/images/aiservicescasestudyinfo/interiorrendering.svg",
    date: "September 2024",
    title: "Interior Rendering & Similarity Search System",
    service: [],
    industry: ["Travel & Transport Logistics"],
    drilldown: [
      {
        img1: "/assets/images/aiservicescasestudyinfo/interiorrendering.svg",
        desc: [
          "Rendering furniture images on a selected interior image based on customer preferences. This helps customers to visualize the interiors with the new objects.",
         "Developed an application which generates a rendered image of a room with furniture or other objects, performing segmentation on the rendered image, and displaying similar images on the UI based on the segmentation. The details of the processes involves are mentioned further.",
        ],
        subTitle1: "Solution Benefits",
        subtitle2: "Technology",
        point: {
          desc1: [
            "Customization: Customers can visualize how different furniture items would look in their space, helping them make informed decisions before purchasing.", 
           "Enhanced User Experience: By providing a visually engaging and interactive experience, users can explore various furniture options easily, increasing engagement and satisfaction.",
           "Efficiency: Automating the process of populating rooms with furniture and segmenting objects can save time and resources compared to manual methods.",
           "Personalization: By displaying similar images based on segmented objects, personalized experience tailored to the user's preferences can be provided.", 
           "Data Collection: Gathering user interactions with the displayed images can provide valuable data for improving recommendations and understanding user preferences.", 
           "Scalability: Once the system is set up, it can be easily scaled to handle a large number of users and images.",
          ],
          desc2: [
            "OpenAI",
            "Replicate",
            "Node JS",
            "React", 
            "Pinecone Vector DB",
          ],
        },
      },
    ],
  },
];

export const intelligentautomationimgs = [
  {
    id: 1,
    img: "/assets/images/aiservicescasestudyinfo/dataconsolidation.svg",
    date: "September 2024",
    title: "Data Consolidation and Commission Tracking System",
    service: ["Application Development"],
    industry: ["Finance"],
    drilldown: [
      {
        img1: "/assets/images/aiservicescasestudyinfo/dataconsolidation1.svg",
        desc: "Develop an automated solution for extracting and analyzing detailed insurance policy data from PDF documents. The objective was to consolidate this data into a structured format and perform a financial comparison to assess the advisability of switching insurance carriers from a business perspective.",
        subTitle1: "Solution",
        subtitle2: "Solution Benefits",
        subtitle3: "Technology",
        point: {
          desc1: [
            "Developed an AI-powered tool that automates the extraction of required data fields from insurance policy PDFs into JSON format. This involves creating prompts to accurately extract information like Insured Name, Date of Birth, Policy Number, etc. The extracted data will be compared with manually stored data in Excel to ensure accuracy. Utilizing Databricks, we'll process and analyse the validated data, performing financial calculations such as surrender values, premiums, payouts, and assessing tax implications. Finally, we'll generate presentations (PPTs) to visualize the analytics, assisting brokers in making informed recommendations about whether to surrender existing policies and adopt new ones.",
          ],
          desc2: [
            "Enhanced Efficiency: Reduces the overall time spent on data extraction tasks by automating the process, allowing staff to focus on more strategic activities. • Improved Accuracy: AI minimizes the likelihood of human error in data extraction, leading to more reliable data for processing and analysis. • Cost Effectiveness: Decreases labour costs associated with manual data entry and mitigates potential financial impacts of errors. • Scalability: The AI solution can easily scale to accommodate growth in data volume without a loss in performance or accuracy. • Informed Decision-Making: Provides accurate and timely data, enhancing the quality of business insights and decisions in policy management."
          ],
          desc3: [
            "GPT-4o-mini (OpenAI): Utilized for its advanced natural language processing capabilities to extract data from unstructured PDF documents.",
            "Python: Serves as the programming language for scripting the extraction process and handling data manipulation. Excel: Used for initial data entry and as a platform for comparison against AI-extracted data.", 
            "JSON: Employed for structuring the output from GPT-4o-mini to facilitate automated comparison and further processing.", 
            "Analytical Tools (e.g., Databricks): Used for deeper data analysis and for generating dynamic, insightful reports.",
          ],
        },
      },
    ],
  },

 {
    id: 2,
    img: "/assets/images/aiservicescasestudyinfo/selfdriving.svg",
    date: "September 2024",
    title: "Self Driving Car Automation",
    service: ["Application Development"],
    industry: ["Finance"],
    drilldown: [
      {
        img1: "/assets/images/aiservicescasestudyinfo/selfdriving1.svg",
        desc: "In this project goal is to explore and implement a diverse set of essential components and techniques necessary for building a self-driving car system. We have divided the project into distinct modules, which include image processing, object detection, and control. All these components work together to achieve autonomous driving capabilities. We have encountered several challenges in self-driving car development, with a primary focus on image processing. These challenges include real-time processing, managing large data volumes, ensuring data quality, noise reduction, object detection and recognition, lane detection, semantic segmentation, calibration, and the implementation of deep learning and neural networks.",
        subTitle1: "Solution Benefits",
        subtitle2: "Technology",
        point: {
          desc1: [
            "Design and implementation of cutting-edge self-driving algorithms and control systems. • Continuous refinement and optimization of software for enhanced performance and safety. • Development of machine learning models for object recognition, decision-making, and behavior prediction.",
          ],
          desc2: [
            "Python",
"Yolo",
"Opencv",
"TensorFlow", 
"Udacity",
"Numpy",
          ],
        },
      },
    ],
  },

];
export const DataIntelligenceAnalyticsimgs=[
  {
    id: 1,
    img: "/assets/images/aiservicescasestudyinfo/aidriven.svg",
    date: "September 2024",
    title: "AI-Driven Predictive Maintenance for Bikes",
    service: [],
    industry: ["Travel & Transport Logistics"],
    drilldown: [
      {
        img1: "/assets/images/aiservicescasestudyinfo/aidriven1.svg",
        desc: [
          "Develop an advanced AI and IoT-based system for predictive maintenance in bikes, utilizing sensor data to anticipate and inform users of necessary maintenance, thereby reducing breakdown risks and enhancing the overall lifespan and safety of the bike. The solution involves integrating IoT sensors onto bikes to collect real-time data on various components and conditions. This data is then analyzed using machine learning algorithms to identify patterns and predict potential maintenance needs before they become critical. The system will alert users and service providers about the predicted maintenance requirements, allowing for timely interventions.",
        ],
        subTitle1: "Solution Benefits",
        subtitle2: "Technology",
        point: {
          desc1: [
            "Automated Content Extraction: Streamlines the process of converting web content and PDFs into digital format, saving users time and effort by automating data retrieval and storage.", 
             "Enhanced Data Understanding: Utilizes advanced embedding techniques and semantic search to analyze and interpret complex banking and healthcare information, providing users with clear, actionable insights.", 
             "Instant Responses: Offers real-time answers to user inquiries, drastically reducing wait times compared to conventional research or customer support channels.", 
             "Accuracy and Relevance: Minimizes the spread of outdated or incorrect information by sourcing data directly from relevant documents, ensuring users receive the most current and accurate answers.", 
             "User-Centric Experience: Features an intuitive user interface that simplifies the search for specific banking and healthcare information, enhancing user satisfaction and engagement.",
              "Round-the-Clock Availability: Provides constant access to information, enabling users to find answers to their questions anytime, enhancing convenience and user satisfaction.", 
              "Personalized Responses: Adapts answers to each user's unique query by analyzing their questions in the context of the vast data stored in the vector database, ensuring highly relevant and customized information.", 
              "Scalable Solution: Designed to efficiently handle growing numbers of users and queries without significant increases in cost or decreases in performance.", 
               "Informed Decision-Making: Empowers users by providing them with comprehensive insights into banking and healthcare questions, aiding them in making well-informed decisions.", 
              "Educational Impact: Enhances users' understanding of complex topics in banking and healthcare, contributing to improved financial and health literacy.",
          ],
          desc2: [
            "Python",
            "IoT sensors",
            "Machine Learning Algorithms", 
          ],
        },
      },
    ],
  },
];
export const VirtualAgentsimgs=[
  {
    id: 1,
    img: "/assets/images/aiservicescasestudyinfo/medicalai.svg",
    date: "September 2024",
    title: "Medical AI Chatbot",
    service: [],
    industry: ["Travel & Transport Logistics"],
    drilldown: [
      {
        img1: "/assets/images/aiservicescasestudyinfo/medicalai1.svg",
        desc: [
          "Develop a chatbot which can provide medical information for pet owners and care givers, offering guidance on veterinary drug usage and animal healthcare. Developed and deployed an AI Chatbot using LLM’s and word embeddings ,which process the user queries and promptly provides accurate and timely information. The detailed process on how the objective was achieved is as follows.",
        ],
        subTitle1: "Solution Benefits",
        subtitle2: "Technology",
        point: {
          desc1: [
            "Accessibility: Provides 24/7 access to accurate veterinary drug and healthcare information, empowering pet owners and caregivers to make informed decisions.", 
            "Instant Responses: The AI Chatbot can provide immediate responses to user queries regarding medication/drug details, significantly reducing response times compared to traditional customer support methods", 
"Reduced Misinformation: Minimizes the risk of inaccurate or outdated information, enhancing the quality of advice and reducing potential harm to animals.", 
"Improved User Experience : Chatbots provide a user-friendly interface that simplifies medication/drug searches, making it easier for users to find relevant listings.", 
"Consistency: Ensures that the information provided is consistently based on the latest medical knowledge and guidelines.",
          ],
          desc2: [
            "OpenAI",
"ReactJS",
"PyCharm",
"Chroma DB",
"Python", 
"Flask", 
"VS Code",
          ],
        },
      },
    ],
  },
  {
    id: 2,
    img: "/assets/images/aiservicescasestudyinfo/enterpriseautomated.svg",
    date: "September 2024",
    title: "Enterprise Automated Communication Solution",
    service: [],
    industry: ["Travel & Transport Logistics"],
    drilldown: [
      {
        img1: "/assets/images/aiservicescasestudyinfo/enterpriseautomated1.svg",
        desc: [
          "Develop an analytical system that scrapes and interprets text from web pages and PDFs, converts this information into embeddings for storage in a vector database, and utilizes a chatbot integrated with a semantic search API to provide specific responses in the banking and healthcare sectors.",
          "Developed and deployed an AI Chatbot using LLM’s and word embeddings ,which process the user queries and promptly provides accurate and timely information. The detailed process on how the objective was achieved is as follows.",
        ],
        subTitle1: "Solution Benefits",
        subtitle2: "Technology",
        point: {
          desc1: [
            "Automated Content Extraction: Streamlines the process of converting web content and PDFs into digital format, saving users time and effort by automating data retrieval and storage.",
            "Enhanced Data Understanding: Utilizes advanced embedding techniques and semantic search to analyze and interpret complex banking and healthcare information, providing users with clear, actionable insights.",
           "Instant Responses: Offers real-time answers to user inquiries, drastically reducing wait times compared to conventional research or customer support channels.", 
           "Accuracy and Relevance: Minimizes the spread of outdated or incorrect information by sourcing data directly from relevant documents, ensuring users receive the most current and accurate answers.", 
           "User-Centric Experience: Features an intuitive user interface that simplifies the search for specific banking and healthcare information, enhancing user satisfaction and engagement.",
           "Round-the-Clock Availability: Provides constant access to information, enabling users to find answers to their questions anytime, enhancing convenience and user satisfaction.", 
          "Personalized Responses: Adapts answers to each user's unique query by analyzing their questions in the context of the vast data stored in the vector database, ensuring highly relevant and customized information.", 
           "Scalable Solution: Designed to efficiently handle growing numbers of users and queries without significant increases in cost or decreases in performance.", 
          "Informed Decision-Making: Empowers users by providing them with comprehensive insights into banking and healthcare questions, aiding them in making well-informed decisions.", 
           "Educational Impact: Enhances users' understanding of complex topics in banking and healthcare, contributing to improved financial and health literacy.",
          ],
          desc2: [
             "Dolly 2.0",
             "Llama-2", 
             "Google Palm", 
             "ReactJS",
             "PyCharm", 
             "Chroma DB", 
             "Python",
             "Flask", 
             "VS Code",
          ],
        },
      },
    ],
  },
  {
    id: 3,
    img: "/assets/images/aiservicescasestudyinfo/propertysearch.svg",
    date: "September 2024",
    title: "Property Search Chatbot",
    service: [],
    industry: ["Travel & Transport Logistics"],
    drilldown: [
      {
        img1: "/assets/images/aiservicescasestudyinfo/propertysearch1.svg",
        desc: [
          "Develop a chatbot which can be used for searching property related information using AI platform.", 
"Developed a chatbot which incorporates property data, crime data and school data harnessing the capabilities of AI. Data flow and details of the processed involved in the development is mentioned further.",
        ],
        subTitle1: "Solution Benefits",
        subtitle2: "Technology",
        point: {
          desc1: [
            "24/7 Availability : Round the clock availability, providing users with access to property information and assistance at any time, even outside regular business hours.", 
            "Instant Responses: Property Search Chatbot can provide immediate responses to user queries regarding property details, significantly reducing response times compared to traditional customer support methods.", 
            "Efficient Property Discovery: Users can quickly find properties that match their criteria by interacting with the chatbot, which streamlines the property search process.", 
            "Improved User Experience : Chatbots provide a user-friendly interface that simplifies property searches, making it easier for users to find relevant listings.",
            "Increased Engagement : By offering a chat-based interface, property search platforms can engage users in realtime conversations, leading to better user retention and increased interaction.", 
            "Scalability : As the volume of users and property listings grows, chatbot can easily scale to handle the increased demand without a proportional increase in operational costs.",
          ],
          desc2: [
            "Google Palm",
"LangChain",
"Streamlit",
"PyCharm", 
"Python",
"Faiss",
          ],
        },
      },
    ],
  },
];

export const aiintegrationimgs=[
  {
    id: 1,
    img: "/assets/images/aiservicescasestudyinfo/decisionengine.svg",
    date: "September 2024",
    title: "Decision Engine",
    service: ["Application Development"],
    industry: ["Finance"],
    drilldown: [
      {
        img1: "/assets/images/aiservicescasestudyinfo/decisionengine1.svg",
        desc: "In the Decision Engine project, effectively managing and visualizing extensive data inputs presented a significant challenge. Additionally, integrating diverse algorithms seamlessly for accurate analysis is complex. Balancing simplicity with comprehensive features while ensuring scalability further adds to the project's challenges.",
        subTitle1: "Solution",
        subtitle2: "Solution Benefits",
        subtitle3: "Technology",
        point: {
          desc1: [
            "Implemented efficient data management techniques like data preprocessing. Utilizing standardized algorithms and modular architecture to enhance seamless integration for accurate analysis.",
          ],
          desc2: [
            "Efficient data management enable users to gain deeper insights into the underlying data, leading to more informed decision-making and potentially better outcomes.",
"Standardizing algorithms can lead to improved performance and reliability of the decision engine.",
"By streamlining data management and algorithm integration, the decision engine enhances workflow efficiency. This translates to time savings for users, allowing them to focus more on analyzing outcomes and making strategic decisions.",
          ],
          desc3: [
            "Python",
"Django",
"PostgresDB",
"DI-Engine Framework",
"ReactJS", 
"reactflow", 
"Recharts", 
"Material UI",
          ],
        },
      },
    ],
  },
]
export const vectorsearchimgs=[
  {
    id: 1,
    img: "/assets/images/aiservicescasestudyinfo/propertysearch.svg",
    date: "September 2024",
    title: "Property Search Chatbot",
    service: [],
    industry: ["Travel & Transport Logistics"],
    drilldown: [
      {
        img1: "/assets/images/aiservicescasestudyinfo/propertysearch1.svg",
        desc: [
          "Develop a chatbot which can be used for searching property related information using AI platform.", 
"Developed a chatbot which incorporates property data, crime data and school data harnessing the capabilities of AI. Data flow and details of the processed involved in the development is mentioned further.",
        ],
        subTitle1: "Solution Benefits",
        subtitle2: "Technology",
        point: {
          desc1: [
            "24/7 Availability : Round the clock availability, providing users with access to property information and assistance at any time, even outside regular business hours.", 
            "Instant Responses: Property Search Chatbot can provide immediate responses to user queries regarding property details, significantly reducing response times compared to traditional customer support methods.", 
            "Efficient Property Discovery: Users can quickly find properties that match their criteria by interacting with the chatbot, which streamlines the property search process.", 
            "Improved User Experience : Chatbots provide a user-friendly interface that simplifies property searches, making it easier for users to find relevant listings.",
            "Increased Engagement : By offering a chat-based interface, property search platforms can engage users in realtime conversations, leading to better user retention and increased interaction.", 
            "Scalability : As the volume of users and property listings grows, chatbot can easily scale to handle the increased demand without a proportional increase in operational costs.",
          ],
          desc2: [
            "Google Palm",
"LangChain",
"Streamlit",
"PyCharm", 
"Python",
"Faiss",
          ],
        },
      },
    ],
  },
  {
    id: 2,
    img: "/assets/images/aiservicescasestudyinfo/medicalai.svg",
    date: "September 2024",
    title: "Medical AI Chatbot",
    service: [],
    industry: ["Travel & Transport Logistics"],
    drilldown: [
      {
        img1: "/assets/images/aiservicescasestudyinfo/medicalai1.svg",
        desc: [
          "Develop a chatbot which can provide medical information for pet owners and care givers, offering guidance on veterinary drug usage and animal healthcare. Developed and deployed an AI Chatbot using LLM’s and word embeddings ,which process the user queries and promptly provides accurate and timely information. The detailed process on how the objective was achieved is as follows.",
        ],
        subTitle1: "Solution Benefits",
        subtitle2: "Technology",
        point: {
          desc1: [
            "Accessibility: Provides 24/7 access to accurate veterinary drug and healthcare information, empowering pet owners and caregivers to make informed decisions.", 
            "Instant Responses: The AI Chatbot can provide immediate responses to user queries regarding medication/drug details, significantly reducing response times compared to traditional customer support methods", 
"Reduced Misinformation: Minimizes the risk of inaccurate or outdated information, enhancing the quality of advice and reducing potential harm to animals.", 
"Improved User Experience : Chatbots provide a user-friendly interface that simplifies medication/drug searches, making it easier for users to find relevant listings.", 
"Consistency: Ensures that the information provided is consistently based on the latest medical knowledge and guidelines.",
          ],
          desc2: [
            "OpenAI",
"ReactJS",
"PyCharm",
"Chroma DB",
"Python", 
"Flask", 
"VS Code",
          ],
        },
      },
    ],
  },
];
